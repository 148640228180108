// import React, { useEffect, useState } from "react";
// import "./panchangam.css";
// import Dayschedule from "../Dayschedule/Dayschedule";
// import News from "../newsevents/News";
// import Events from "../newsevents/Events";
// import Panchang from "../panchang/Panchang";
// import { getApi, postApi } from "../../../ApiServices/api";
// import { apiEndPoints } from "../../../ApiServices/config";
// import moment from "moment";

// function Panchangam() {
//   const date = new Date();

//   const [dailySchedule, setDailySchedule] = useState();
//   const [dailyPanchangam, setDailyPanchangam] = useState();
//   const [dailyNews, setDailyNews] = useState();
//   const [allEvents, setAllEvents] = useState();

//   const payload = {
//     date,
//   };

//   const getDaySchedule = async () => {
//     const response = await postApi(apiEndPoints.todaySchedule, payload);
//     if (response && response.status === 200) {
//       setDailySchedule(response?.data?.data);
//     }
//   };
//   const getPanchangam = async () => {
//     const response = await postApi(apiEndPoints.panchang, payload);
//     if (response && response.status === 200) {
//       setDailyPanchangam(response?.data?.data);
//     }
//   };

//   const newsPayload = {
//     limits: 5,
//   };

//   const getNews = async () => {
//     const response = await postApi(apiEndPoints.news, newsPayload);
//     if (response && response.status === 200) {
//       setDailyNews(response?.data?.data);
//     }
//   };

//   const getAllEvents = async () => {
//     const response = await getApi(apiEndPoints.allHomePageEvents);
//     if (response && response.status === 200) {
//       setAllEvents(response?.data?.data);
//     }
//   };

//   useEffect(() => {
//     getDaySchedule();
//     getPanchangam();
//     getNews();
//     getAllEvents();
//   }, []);

//   return (
//     <>
//       <div className="container-fluid about-temple-bg panchang-btm">
//         <div className="container panchang-top-pad">
//           <div className="row g-4 mb-4">
//             <div className="col-lg-6 col-md-6">
//               <div className="text-center bg-white h-100 pt-0 panchang">
//                 <div className="title-bg">
//                   <h2>Panchangam </h2>
//                 </div>
//                 <div className="sub-title-bg">
//                   <h2>
//                     {new Date().toLocaleDateString("en-US", {
//                       timeZone: "America/New_York",
//                       weekday: "long",
//                       month: "long",
//                       day: "numeric",
//                       year: "numeric",
//                     })}

//                     <span>(FAIRFAX, VIRGINIA, AMERICA (UNITED STATES))</span>
//                   </h2>
//                 </div>
//                 <div className="panchang-content1">
//                   <Panchang dailyPanchangam={dailyPanchangam} />
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-6 col-md-6">
//               <div className="text-center bg-white h-100 pt-0 panchang">
//                 <div className="title-bg">
//                   <h2>Day Schedule</h2>
//                 </div>
//                 <div className="sub-title-bg">
//                   <h2>{date.toDateString()}</h2>
//                 </div>
//                 <div className="panchang-content">
//                   <Dayschedule dailySchedule={dailySchedule} />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="container-fluid panchang-btm">
//         <div className="container panchang-top-pad">
//           <div className="row g-4 mb-5">
//             {/* ///----------------------- */}
//             <div className="col-lg-6 col-md-6">
//               <div className="text-center h-100 pt-0 panchang">
//                 <News dailyNews={dailyNews} />
//               </div>
//             </div>
//             <div className="col-lg-6 col-md-6">
//               <div className="text-center h-100 pt-0 panchang">
//                 <Events allEvents={allEvents} />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Panchangam;

import React from "react";
import "./panchangam.css";
import Dayschedule from "../Dayschedule/Dayschedule";
import News from "../newsevents/News";
import Events from "../newsevents/Events";
import Panchang from "../panchang/Panchang";
// import { getApi, postApi } from "../../../../ApiServices/api";
// import { apiEndPoints } from "../../../../ApiServices/config";
import moment from "moment";
function Panchangam(props) {
  const { dailySchedule, dailyPanchangam, dailyNews, allEvents } = props;
  // const date = new Date();
  // const [dailySchedule, setDailySchedule] = useState();
  // const [dailyPanchangam, setDailyPanchangam] = useState();
  // const [dailyNews, setDailyNews] = useState();
  // const [allEvents, setAllEvents] = useState();
  // const payload = {
  //   date,
  // };
  // const getDaySchedule = async () => {
  //   const response = await postApi(apiEndPoints.todaySchedule, payload);
  //   if (response && response.status === 200) {
  //     setDailySchedule(response?.data?.data);
  //   }
  // };
  // const getPanchangam = async () => {
  //   const response = await postApi(apiEndPoints.panchang, payload);
  //   if (response && response.status === 200) {
  //     setDailyPanchangam(response?.data?.data);
  //   }
  // };
  // const newsPayload = {
  //   limits: 5,
  // };
  // const getNews = async () => {
  //   const response = await postApi(apiEndPoints.news, newsPayload);
  //   if (response && response.status === 200) {
  //     setDailyNews(response?.data?.data);
  //   }
  // };
  // const getAllEvents = async () => {
  //   const response = await getApi(apiEndPoints.allHomePageEvents);
  //   if (response && response.status === 200) {
  //     setAllEvents(response?.data?.data);
  //   }
  // };
  // useEffect(() => {
  //   getDaySchedule();
  //   getPanchangam();
  //   getNews();
  //   getAllEvents();
  // }, []);
  return (
    <>
      <div className="container-fluid about-temple-bg panchang-btm">
        <div className="container panchang-top-pad">
          <div className="row g-4 mb-4">
            <div className="col-lg-6 col-md-6">
              <div className="text-center bg-white h-100 pt-0 panchang">
                <div className="title-bg">
                  <h2>Day Schedule</h2>
                </div>
                <div className="sub-title-bg">
                  <h2>
                    {moment()
                      .tz("America/New_York")
                      .format("dddd, MMMM Do YYYY")}
                  </h2>
                </div>
                <div className="panchang-content">
                  <Dayschedule dailySchedule={dailySchedule} />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="text-center h-100 pt-0 panchang">
                <Events allEvents={allEvents} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid panchang-btm">
        <div className="container panchang-top-pad">
          <div className="row g-4 mb-5">
            <div className="col-lg-6 col-md-6">
              <div className="text-center h-100 pt-0 panchang">
                <News dailyNews={dailyNews} />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="text-center bg-white h-100 pt-0 panchang">
                <div className="title-bg">
                  <h2>Panchangam </h2>
                </div>
                <div className="sub-title-bg">
                  <h2>
                    {new Date().toLocaleDateString("en-US", {
                      timeZone: "America/New_York",
                      weekday: "long",
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}
                    <span>(FAIRFAX, VIRGINIA, AMERICA (UNITED STATES))</span>
                  </h2>
                </div>
                <div className="panchang-content1">
                  <Panchang dailyPanchangam={dailyPanchangam} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Panchangam;
