/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import "./registration.css";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  TextField,
  InputAdornment,
  FormGroup,
  SvgIcon,
  Tooltip,
  Grid,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Button } from "react-bootstrap";
import { MuiTelInput } from "mui-tel-input";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { apiEndPoints } from "../../ApiServices/config";
import { getApi, postApi } from "../../ApiServices/api";
import {
  AddCircle,
  Info,
  InfoOutlined,
  InfoRounded,
  Remove,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import ConsentDialog from "../../components/common/ConsentDialog";
import toast from "react-hot-toast";
import useLoadMasterData from "./customHooks";
function Registration() {
  const [spouseAccordionExpand, setSpouseAccordionExpand] = useState(false);
  const [childrenAccordionExpand, setChildrenAccordionExpand] = useState(false);
  const [fields, setFields] = useState([{}]);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [childIndex, setChildIndex] = useState(0);
  const {
    masterData,
    setMasterData,
    childnakshatra,
    setchildNakshatra,
    childrenrashi,
    setChildrenRashi,
    spouserashi,
    setSpouseRashi,
    spousechildnakshatra,
    setspouseNakshatra,
    getMasterData,
  } = useLoadMasterData(childIndex);
  const [mobile, setMobile] = useState({
    main: "",
    home: "",
    office: "",
  });
  const navigate = useNavigate();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({});

  const handleTogglePasswordVisibility1 = () => {
    setShowPassword1((prevShowPassword) => !prevShowPassword);
  };

  const handleTogglePasswordVisibility2 = () => {
    setShowPassword2((prevShowPassword) => !prevShowPassword);
  };

  const handleMobileChange = (key, value) => {
    setMobile((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    getMasterData();
  }, []);

  const onSubmit = async (data) => {
    try {
      const response = await postApi(apiEndPoints.devoteeRegister, data);
      if (response?.data?.status === 0) {
        toast.error(response?.data?.message);
      } else if (response?.data?.status === 1) {
        toast.success(response?.data?.message);
        localStorage.setItem(
          "devoteeData",
          JSON.stringify(response?.data?.data)
        );
        navigate("/registrationsuccess");
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      toast.error(`Registration failed: , ${error}`);
    }
  };

  return (
    <>
      {/* <pre>Rashi</pre> */}
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Registration</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">Registration</Link>
          </li>
          <li className="breadcrumb-item active">Registration</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Durga Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Registration</span>
          </h1>
        </div>
        <Box
          sx={{
            "& .MuiTextField-root": {
              minWidth: "100% !important",
            },
            "& .dropdown-margin-top": {
              marginTop: "1px !important",
            },
            "& .accordion-background": {
              boxShadow: "none !important",
            },
            "& .accordion-background::before": {
              backgroundColor: "transparent !important",
            },
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <h4 className="registration-h4">Personal Information</h4>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("first_name", {
                    required: "First Name is required",
                  })}
                  margin="normal"
                  fullWidth
                  label="First Name *"
                  error={errors?.first_name?.message}
                  helperText={errors?.first_name?.message}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("middle_name")}
                  margin="normal"
                  fullWidth
                  label="Middle Name"
                  error={errors?.middle_name?.message}
                  helperText={errors?.middle_name?.message}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("last_name", {
                    required: "Last Name is required",
                  })}
                  margin="normal"
                  fullWidth
                  label="Last Name *"
                  error={errors?.last_name?.message}
                  helperText={errors?.last_name?.message}
                />
              </div>

              <div className="col-lg-4 col-md-6">
                <Controller
                  control={control}
                  rules={{ required: "Mobile is required" }}
                  name="mobile"
                  render={({ field }) => {
                    return (
                      <MuiTelInput
                        {...field}
                        value={mobile.main}
                        onChange={(event, event2) => {
                          handleMobileChange("main", event);
                          field.onChange(+event2?.numberValue);
                        }}
                        defaultCountry="US"
                        sx={{ mt: "16px", mb: "8px" }}
                        fullWidth
                        label="Mobile *"
                        error={errors?.mobile?.message}
                        helperText={errors?.mobile?.message}
                      />
                    );
                  }}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("email", { required: "Email is required" })}
                  margin="normal"
                  fullWidth
                  label="Email *"
                  error={errors?.email?.message}
                  helperText={errors?.email?.message}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("confirm_email", {
                    required: "Please re-enter email",
                  })}
                  margin="normal"
                  fullWidth
                  label="Re-Enter Email *"
                  error={errors?.confirm_email?.message}
                  helperText={errors?.confirm_email?.message}
                />
              </div>
              <hr className="mt-3" />
              <h4 className="registration-h4">Optional Fields</h4>
              <div className="col-lg-4 col-md-6">
                <div className="date-top-mrgn">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <Controller
                        control={control}
                        name="dob"
                        render={({ field }) => {
                          return (
                            <div className="col-lg-11 col-md-6 d-flex flex-row">
                              <DatePicker
                                {...field}
                                label="Date of Birth (optional)"
                                error={errors?.dob?.message}
                              />

                              <Tooltip title="DOB: This information is used to send birthday blessings from the temple and reminders a week before so devotees can plan and perform any prayers on their special day. Additionally, it helps in planning events for specific age groups and sending personal invitations, such as events for seniors or students before exam season/Saraswati Puja.">
                                <IconButton>
                                  <InfoOutlined />
                                </IconButton>
                              </Tooltip>
                            </div>
                          );
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <FormHelperText error>{errors?.dob?.message}</FormHelperText>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 ">
                <Grid container>
                  <Grid item xs={11}>
                    <Controller
                      control={control}
                      name="nakshatram"
                      render={({ field, fieldState }) => {
                        return (
                          <FormControl
                            key={field.value}
                            fullWidth
                            error={fieldState?.error?.message}
                            className="dropdown-margin-top"
                          >
                            <InputLabel id="demo-simple-select-label">
                              Nakshatra (optional)
                            </InputLabel>
                            <Select
                              {...field}
                              labelId="demo-simple-select-label"
                              label="Nakshatra"
                              onChange={async (e) => {
                                field.onChange(e.target.value);
                                try {
                                  const responseData =
                                    e.target.value == 0
                                      ? await getApi(apiEndPoints.rashi)
                                      : await postApi(
                                          apiEndPoints.rashibynakshatramid,
                                          {
                                            _id: e.target.value,
                                          }
                                        );
                                  setMasterData((prevState) => ({
                                    ...prevState,
                                    rashiData: responseData?.data.data,
                                  }));
                                } catch (error) {
                                  console.error(
                                    "Error fetching state data:",
                                    error
                                  );
                                  // Optionally handle error state here
                                }
                              }}
                            >
                              {masterData?.nakshatraData
                                ?.sort((a, b) => a.name.localeCompare(b.name))
                                .map((item) => (
                                  <MenuItem key={item._id} value={item._id}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText error>
                              {fieldState?.error?.message}
                            </FormHelperText>
                          </FormControl>
                        );
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Tooltip
                      title="Gotra/Nakshatra/Rashi: These details are used for Sankalp during Pujas or blessings performed by priests.
Nakshatra/Rashi information is also helpful in sending notifications during solar and lunar eclipses when certain groups of Rashis may need to perform parihara Puja."
                    >
                      <IconButton>
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </div>
              {/* Rashi select box */}
              <div className="col-lg-4 col-md-6">
                <Grid container>
                  <Grid item xs={11}>
                    <Controller
                      control={control}
                      name="rashi"
                      render={({ field, fieldState }) => {
                        return (
                          <FormControl
                            key={field.value}
                            fullWidth
                            error={fieldState?.error?.message}
                            className="dropdown-margin-top"
                          >
                            <InputLabel id="demo-simple-select-label">
                              Rashi (optional)
                            </InputLabel>
                            <Select
                              {...field}
                              labelId="demo-simple-select-label"
                              label="Rashi"
                              onChange={async (e) => {
                                field.onChange(e.target.value);
                                try {
                                  const responseData =
                                    e.target.value == 0
                                      ? await getApi(apiEndPoints.nakshatram)
                                      : await postApi(
                                          apiEndPoints.nakshatrambyrashi,
                                          {
                                            _id: e.target.value,
                                          }
                                        );

                                  // After receiving the response, update the state
                                  setMasterData((prevState) => ({
                                    ...prevState,
                                    nakshatraData: responseData?.data.data,
                                  }));
                                } catch (error) {
                                  console.error(
                                    "Error fetching state data:",
                                    error
                                  );
                                  // Optionally handle error state here
                                }
                              }}
                            >
                              {masterData?.rashiData
                                ?.sort((a, b) =>
                                  a.rashi_name.localeCompare(b.rashi_name)
                                )
                                ?.map((item) => (
                                  <MenuItem key={item._id} value={item._id}>
                                    {item.rashi_name}
                                  </MenuItem>
                                ))}

                              <MenuItem value="0">unknown</MenuItem>
                            </Select>
                            <FormHelperText error>
                              {fieldState?.error?.message}
                            </FormHelperText>
                          </FormControl>
                        );
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Tooltip
                      title="Gotra/Nakshatra/Rashi: These details are used for Sankalp during Pujas or blessings performed by priests.
Nakshatra/Rashi information is also helpful in sending notifications during solar and lunar eclipses when certain groups of Rashis may need to perform parihara Puja."
                    >
                      <IconButton>
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </div>
              <div className="col-lg-4 col-md-6">
                <Grid container>
                  <Grid item xs={11}>
                    <TextField
                      {...register("gotram")}
                      margin="normal"
                      fullWidth
                      label="Gotram (optional)"
                      error={errors?.gotram?.message}
                      helperText={errors?.gotram?.message}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    // className="d-flex justify-content-center align-items-center"
                  >
                    <Tooltip
                      title="Gotra/Nakshatra/Rashi: These details are used for Sankalp during Pujas or blessings performed by priests.
Nakshatra/Rashi information is also helpful in sending notifications during solar and lunar eclipses when certain groups of Rashis may need to perform parihara Puja."
                    >
                      <IconButton>
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </div>
              <div className="col-lg-4 col-md-6">
                <Controller
                  control={control}
                  name="language"
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl
                        key={field.value}
                        fullWidth
                        error={fieldState?.error?.message}
                        className="dropdown-margin-top"
                      >
                        <InputLabel id="demo-simple-select-label">
                          Preferred Language (optional)
                        </InputLabel>
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          label="Preferred Language"
                          defaultValue={field.value}
                        >
                          {masterData?.languageData
                            ?.sort((a, b) => a.name.localeCompare(b.name))
                            ?.map((item) => (
                              <MenuItem key={item._id} value={item._id}>
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText error>
                          {fieldState?.error?.message}
                        </FormHelperText>
                      </FormControl>
                    );
                  }}
                />
              </div>

              <Accordion
                expanded={spouseAccordionExpand}
                onChange={() => {
                  setSpouseAccordionExpand(!spouseAccordionExpand);
                }}
                className="accordion-background"
              >
                <AccordionSummary
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>
                    {spouseAccordionExpand ? <Remove /> : <AddIcon />}
                    Spouse
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <h4 className="registration-h4">Spouse Information</h4>
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <TextField
                        {...register(`spouse.0.first_name`)}
                        margin="normal"
                        fullWidth
                        label="First Name *"
                      />
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <TextField
                        {...register(`spouse.0.middle_name`)}
                        margin="normal"
                        fullWidth
                        label="Middle Name"
                      />
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <TextField
                        {...register(`spouse.0.last_name`)}
                        margin="normal"
                        fullWidth
                        label="Last Name *"
                      />
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="date-top-mrgn">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <Controller
                              control={control}
                              name={`spouse.0.dob`}
                              render={({ field }) => {
                                return (
                                  <div className="col-lg-11 col-md-6 d-flex flex-row">
                                    <DatePicker
                                      {...field}
                                      label="Date of Birth (optional)"
                                    />
                                    <Tooltip title="DOB: This information is used to send birthday blessings from the temple and reminders a week before so devotees can plan and perform any prayers on their special day. Additionally, it helps in planning events for specific age groups and sending personal invitations, such as events for seniors or students before exam season/Saraswati Puja.">
                                      <IconButton>
                                        <InfoOutlined />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                );
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                        <p>{errors?.dob?.message}</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <Grid container>
                        <Grid item xs={11}>
                          <FormControl
                            fullWidth
                            className="dropdown-margin-top"
                          >
                            <InputLabel id="demo-simple-select-label">
                              Nakshatra (optional)
                            </InputLabel>
                            <Select
                              {...register(`spouse.0.nakshatram`)}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Nakshatra"
                              onChange={async (e) => {
                                setValue(`spouse.0.nakshatram`, e.target.value);

                                try {
                                  const responseData =
                                    e.target.value == 0
                                      ? await getApi(apiEndPoints.rashi)
                                      : await postApi(
                                          apiEndPoints.rashibynakshatramid,
                                          {
                                            _id: e.target.value,
                                          }
                                        );

                                  // After receiving the response, update the state
                                  setSpouseRashi(responseData?.data?.data);
                                } catch (error) {
                                  console.error(
                                    "Error fetching state data:",
                                    error
                                  );
                                  // Optionally handle error state here
                                }
                              }}
                            >
                              {spousechildnakshatra
                                ?.sort((a, b) => a.name.localeCompare(b.name))
                                ?.map((item) => (
                                  <MenuItem key={item._id} value={item._id}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                              <MenuItem value="0">unknown</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <Tooltip
                            title="Gotra/Nakshatra/Rashi: These details are used for Sankalp during Pujas or blessings performed by priests.
Nakshatra/Rashi information is also helpful in sending notifications during solar and lunar eclipses when certain groups of Rashis may need to perform parihara Puja."
                          >
                            <IconButton>
                              <InfoOutlined />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </div>
                    {/* Rashi select box */}
                    <div className="col-lg-4 col-md-6">
                      <Grid container>
                        <Grid item xs={11}>
                          <FormControl
                            fullWidth
                            className="dropdown-margin-top"
                          >
                            <InputLabel id="demo-simple-select-label">
                              Rashi (optional)
                            </InputLabel>
                            <Select
                              {...register(`spouse.0.rashi`)}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Rashi"
                              onChange={async (e) => {
                                setValue(`spouse.0.rashi`, e.target.value);

                                try {
                                  const responseData =
                                    e.target.value == 0
                                      ? await getApi(apiEndPoints.nakshatram)
                                      : await postApi(
                                          apiEndPoints.nakshatrambyrashi,
                                          {
                                            _id: e.target.value,
                                          }
                                        );

                                  // After receiving the response, update the state
                                  setspouseNakshatra(responseData?.data?.data);
                                } catch (error) {
                                  console.error(
                                    "Error fetching state data:",
                                    error
                                  );
                                  // Optionally handle error state here
                                }
                              }}
                            >
                              {spouserashi
                                ?.sort((a, b) =>
                                  a.rashi_name.localeCompare(b.rashi_name)
                                )
                                ?.map((item) => (
                                  <MenuItem key={item._id} value={item._id}>
                                    {item.rashi_name}
                                  </MenuItem>
                                ))}
                              <MenuItem value="0">unknown</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <Tooltip
                            title="Gotra/Nakshatra/Rashi: These details are used for Sankalp during Pujas or blessings performed by priests.
Nakshatra/Rashi information is also helpful in sending notifications during solar and lunar eclipses when certain groups of Rashis may need to perform parihara Puja."
                          >
                            <IconButton>
                              <InfoOutlined />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </div>

                    <div className="col-lg-4 col-md-6">
                      <TextField
                        {...register(`spouse.0.email`)}
                        margin="normal"
                        fullWidth
                        label="Email (optional)"
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={childrenAccordionExpand}
                onChange={() => {
                  setChildrenAccordionExpand(!childrenAccordionExpand);
                }}
                className="accordion-background"
              >
                <AccordionSummary
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>
                    {childrenAccordionExpand ? <Remove /> : <AddIcon />}
                    Children
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box display="flex" justifyContent="space-between">
                    <Typography>
                      <h4 className="registration-h4">Children Information</h4>
                    </Typography>
                  </Box>
                  {fields.map((item, index) => (
                    <div key={item.id} className="row">
                      <ConsentDialog
                        index={index}
                        onAgree={(indexToRemove) => {
                          setFields(
                            fields.filter((_, index) => index !== indexToRemove)
                          );
                        }}
                      />
                      <div className="col-lg-4 col-md-6">
                        <TextField
                          {...register(`childrens[${index}].first_name`)}
                          margin="normal"
                          fullWidth
                          label="First Name *"
                        />
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <TextField
                          {...register(`childrens[${index}].middle_name`)}
                          margin="normal"
                          fullWidth
                          label="Middle Name"
                        />
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <TextField
                          {...register(`childrens[${index}].last_name`)}
                          margin="normal"
                          fullWidth
                          label="Last Name *"
                        />
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="date-top-mrgn">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                              <Controller
                                name={`childrens[${index}].dob`}
                                control={control}
                                render={({ field }) => (
                                  <div className="col-lg-11 col-md-6 d-flex flex-row">
                                    <DatePicker
                                      {...field}
                                      label="Date of Birth (optional)"
                                      value={field.value ? field.value : null}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                      onChange={(date) => {
                                        // Keep the date as a Moment object
                                        field.onChange(date);
                                      }}
                                    />
                                    <Tooltip title="DOB: This information is used to send birthday blessings from the temple and reminders a week before so devotees can plan and perform any prayers on their special day. Additionally, it helps in planning events for specific age groups and sending personal invitations, such as events for seniors or students before exam season/Saraswati Puja.">
                                      <IconButton>
                                        <InfoOutlined />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <Grid container>
                          <Grid item xs={11}>
                            <FormControl
                              fullWidth
                              className="dropdown-margin-top"
                            >
                              <InputLabel id="demo-simple-select-label">
                                Nakshatra (optional)
                              </InputLabel>
                              <Select
                                {...register(`childrens.${index}.nakshatram`)}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Nakshatra"
                                onChange={async (e) => {
                                  setValue(
                                    `childrens.${index}.nakshatram`,
                                    e.target.value
                                  );
                                  try {
                                    const responseData =
                                      e.target.value == 0
                                        ? await getApi(apiEndPoints.rashi)
                                        : await postApi(
                                            apiEndPoints.rashibynakshatramid,
                                            {
                                              _id: e.target.value,
                                            }
                                          );

                                    // After receiving the response, update the state
                                    setChildrenRashi((prev) => {
                                      const updatedChildrenRashi = [...prev];
                                      updatedChildrenRashi[index] =
                                        responseData?.data.data;
                                      return updatedChildrenRashi;
                                    });
                                  } catch (error) {
                                    console.error(
                                      "Error fetching state data:",
                                      error
                                    );
                                    // Optionally handle error state here
                                  }
                                }}
                              >
                                {childnakshatra[index]
                                  ?.sort((a, b) => a.name.localeCompare(b.name))
                                  ?.map((item) => (
                                    <MenuItem key={item._id} value={item._id}>
                                      {item.name}
                                    </MenuItem>
                                  ))}
                                <MenuItem value="0">unknown</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            className="d-flex justify-content-center align-items-center"
                          >
                            <Tooltip
                              title="Gotra/Nakshatra/Rashi: These details are used for Sankalp during Pujas or blessings performed by priests.
Nakshatra/Rashi information is also helpful in sending notifications during solar and lunar eclipses when certain groups of Rashis may need to perform parihara Puja."
                            >
                              <IconButton>
                                <InfoOutlined />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </div>

                      {/* children rashi */}

                      <div className="col-lg-4 col-md-6">
                        <Grid container>
                          <Grid item xs={11}>
                            <FormControl
                              fullWidth
                              className="dropdown-margin-top"
                            >
                              <InputLabel id="demo-simple-select-label">
                                Rashi (optional)
                              </InputLabel>

                              <Select
                                {...register(`childrens.${index}.rashi`)}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Rashi"
                                onChange={async (e) => {
                                  setValue(
                                    `childrens.${index}.rashi`,
                                    e.target.value
                                  );
                                  try {
                                    const responseData =
                                      e.target.value == 0
                                        ? await getApi(apiEndPoints.nakshatram)
                                        : await postApi(
                                            apiEndPoints.nakshatrambyrashi,
                                            {
                                              _id: e.target.value,
                                            }
                                          );

                                    // After receiving the response, update the state
                                    setchildNakshatra((prev) => {
                                      const updatedChildrenNakshatra = [
                                        ...prev,
                                      ];
                                      updatedChildrenNakshatra[index] =
                                        responseData?.data?.data;
                                      return updatedChildrenNakshatra;
                                    });
                                  } catch (error) {
                                    console.error(
                                      "Error fetching state data:",
                                      error
                                    );
                                    // Optionally handle error state here
                                  }
                                }}
                              >
                                {childrenrashi[index]
                                  ?.sort((a, b) =>
                                    a.rashi_name.localeCompare(b.rashi_name)
                                  )
                                  ?.map((item) => (
                                    <MenuItem key={item._id} value={item._id}>
                                      {item.rashi_name}
                                    </MenuItem>
                                  ))}
                                <MenuItem value="0">unknown</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            className="d-flex justify-content-center align-items-center"
                          >
                            <Tooltip
                              title="Gotra/Nakshatra/Rashi: These details are used for Sankalp during Pujas or blessings performed by priests.
Nakshatra/Rashi information is also helpful in sending notifications during solar and lunar eclipses when certain groups of Rashis may need to perform parihara Puja."
                            >
                              <IconButton>
                                <InfoOutlined />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </div>

                      <div className="col-lg-4 col-md-6">
                        <TextField
                          {...register(`childrens[${index}].email`)}
                          margin="normal"
                          fullWidth
                          label="Email (optional)"
                        />
                      </div>
                    </div>
                  ))}
                  <Box display="flex" justifyContent="end">
                    <IconButton
                      type="button"
                      color="success"
                      onClick={async () => {
                        setFields([...fields, {}]);
                        const result = await Promise.all([
                          getApi(apiEndPoints.nakshatram),
                          getApi(apiEndPoints.rashi),
                        ]);

                        setchildNakshatra((prev) => {
                          const updatedChildrenNakshatra = [...prev];
                          updatedChildrenNakshatra[childIndex + 1] =
                            result[0]?.data?.data;
                          return updatedChildrenNakshatra;
                        });
                        setChildrenRashi((prev) => {
                          const updatedChildrenRashi = [...prev];
                          updatedChildrenRashi[childIndex + 1] =
                            result[1]?.data?.data;
                          return updatedChildrenRashi;
                        });
                        setChildIndex(childIndex + 1);
                      }}
                    >
                      <AddCircle />
                    </IconButton>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </div>
            <hr />
            <h4 className="registration-h4">Contact Information</h4>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("address", { required: "Address is required" })}
                  margin="normal"
                  fullWidth
                  label="Street Address *"
                  error={errors?.address?.message}
                  helperText={errors?.address?.message}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <Controller
                  control={control}
                  rules={{ required: "Country is required" }}
                  name="country"
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl
                        key={field.value}
                        fullWidth
                        error={fieldState?.error?.message}
                        className="dropdown-margin-top"
                      >
                        <InputLabel id="demo-simple-select-label">
                          Select Country *
                        </InputLabel>
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          label="Select Country *"
                          onChange={async (e) => {
                            field.onChange(e.target.value);
                            try {
                              const responseData = await postApi(
                                apiEndPoints.state,
                                {
                                  country_id: e.target.value,
                                }
                              );

                              // After receiving the response, update the state
                              setMasterData((prevState) => ({
                                ...prevState,
                                statesData: responseData?.data.data,
                              }));
                            } catch (error) {
                              console.error(
                                "Error fetching state data:",
                                error
                              );
                              // Optionally handle error state here
                            }
                          }}
                        >
                          {masterData?.countryData
                            ?.sort((a, b) => a.name.localeCompare(b.name))
                            ?.map((item) => (
                              <MenuItem key={item._id} value={item._id}>
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText error>
                          {fieldState?.error?.message}
                        </FormHelperText>
                      </FormControl>
                    );
                  }}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <Controller
                  control={control}
                  name="state"
                  rules={{ required: "State is required" }}
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl
                        key={field.value}
                        fullWidth
                        error={fieldState?.error?.message}
                        className="dropdown-margin-top"
                      >
                        <InputLabel id="demo-simple-select-label">
                          Select State *
                        </InputLabel>
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          label="Select State *"
                          defaultValue={field.value}
                        >
                          {masterData?.statesData
                            ?.sort((a, b) => a.name.localeCompare(b.name))
                            ?.map((item) => (
                              <MenuItem key={item._id} value={item._id}>
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText error>
                          {fieldState?.error?.message}
                        </FormHelperText>
                      </FormControl>
                    );
                  }}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("city", { required: "City is required" })}
                  margin="normal"
                  fullWidth
                  label="City *"
                  error={errors?.city?.message}
                  helperText={errors?.city?.message}
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("zip_code", {
                    required: "Zip Code is required",
                  })}
                  margin="normal"
                  fullWidth
                  label="Zip Code *"
                  error={errors?.zip_code?.message}
                  helperText={errors?.zip_code?.message}
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <Controller
                  control={control}
                  name="home_phone"
                  render={({ field }) => {
                    return (
                      <MuiTelInput
                        {...field}
                        value={mobile.home}
                        onChange={(event, event2) => {
                          handleMobileChange("home", event);
                          field.onChange(+event2?.numberValue);
                        }}
                        defaultCountry="US"
                        sx={{ mt: "16px", mb: "8px" }}
                        fullWidth
                        label="Home Phone"
                        error={errors?.home_phone?.message}
                        helperText={errors?.home_phone?.message}
                      />
                    );
                  }}
                />
              </div>

              <div className="col-lg-4 col-md-6">
                <Controller
                  control={control}
                  name="office_phone"
                  render={({ field }) => {
                    return (
                      <MuiTelInput
                        {...field}
                        value={mobile.office}
                        onChange={(event, event2) => {
                          handleMobileChange("office", event);
                          field.onChange(+event2?.numberValue);
                        }}
                        defaultCountry="US"
                        sx={{ mt: "16px", mb: "8px" }}
                        fullWidth
                        label="Office Phone"
                        error={errors?.office_phone?.message}
                        helperText={errors?.office_phone?.message}
                      />
                    );
                  }}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("secondary_email")}
                  margin="normal"
                  fullWidth
                  label="Alternate Email"
                  error={errors?.secondary_email?.message}
                  helperText={errors?.secondary_email?.message}
                />
              </div>
            </div>
            <hr />
            <h4 className="registration-h4">Login Information</h4>
            <div className="row mb-5">
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("user_name", {
                    required: "User Name is required",
                  })}
                  margin="normal"
                  fullWidth
                  label="User Name or Email *"
                  error={errors?.user_name?.message}
                  helperText={errors?.user_name?.message}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("password", {
                    required: "Password is required",
                  })}
                  type={showPassword1 ? "text" : "password"}
                  margin="normal"
                  fullWidth
                  label="Password *"
                  error={!!errors?.password}
                  helperText={errors?.password?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility1}
                          edge="end"
                        >
                          {showPassword1 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("confirm_password", {
                    required: "Please Re enter is required",
                  })}
                  type={showPassword2 ? "text" : "password"}
                  margin="normal"
                  fullWidth
                  label="Confirm Password *"
                  error={!!errors?.confirm_password}
                  helperText={errors?.confirm_password?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility2}
                          edge="end"
                        >
                          {showPassword2 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <hr />
            <div>
              <h4 className="registration-h4">Communication Preferences</h4>
              <FormGroup>
                <FormControlLabel
                  {...register("is_subscribed")}
                  control={<Checkbox />}
                  label="Subscribe Newsletter"
                />
                <FormControlLabel
                  {...register("isGetEmailNotifications")}
                  control={<Checkbox />}
                  label="Get notifications from temple via Email"
                />
                <FormControlLabel
                  {...register("isGetSmsNotifications")}
                  control={<Checkbox />}
                  label="Get notifications from temple via SMS"
                />
              </FormGroup>
            </div>
            <p className="mt-3">
              Password Must Contain 8 Characters, One Uppercase, One Lowercase,
              One Number and One Special Case Character.
            </p>
            <Button
              variant="warning text-white btn-slider slider-btn col-lg-3 col-md-6 mt-3 py-3"
              type="submit"
            >
              Submit
            </Button>
          </form>
        </Box>
      </div>
    </>
  );
}

export default Registration;
