import React from "react";
import "./panchang.css";
import { Link } from "react-router-dom";

function Panchang() {
  return (
    <>
      <div className="pancgan-table">
        <iframe
          id="IDpanchang"
          src="https://www.mypanchang.com/newsite/compactfeed.php?locid=4758023&mode=full&pt=12pt"
          width="100%"
          height="100%"
          frameBorder="0"
          scrolling="auto"
        ></iframe>
      </div>
      <div className="d-flex justify-content-end">
        <Link
          className="btn text-white py-2 m-2 px-4 btn-right"
          to="/fullpanchang"
        >
          Full Panchang
        </Link>
      </div>
    </>
  );
}

export default Panchang;
