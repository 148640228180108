import React, { useEffect } from "react";
import Slider from "./components/slider/Slider";
import AboutTemple from "./components/abouttemple/AboutTemple";
import Sportlight from "./components/spotlight/Sportlight";
import Panchangam from "./components/panchangam/Panchangam";
import Gallery from "./components/gallery/Gallery";
import Services from "./components/home-services/Services";
import Templetime from "./components/temple-time/Templetime";
import Newsupdate from "./components/newsupdate/Newsupdate";
import Yogaclass from "./components/yogaclass/Yogaclass";
import FloatingSidemenu from "./components/floatingmenu/FloatingSidemenu";
import PopupPage from "./components/popupLoad/PopupPage";
import useLoadMasterDataHome from "./customHooks";

function Home() {
  const { masterData, getMasterData, gallery } = useLoadMasterDataHome();
  useEffect(() => {
    getMasterData();
  }, []);

  return (
    <>
      {/* <PopupPage /> */}
      <Slider slider={masterData.slider} />
      <Newsupdate
        monthlyNewsletter={masterData.monthlyNewsletter}
        annualCalendar={masterData.annualCalendar}
        latestNews={masterData.latestNews}
      />
      <Panchangam
        dailySchedule={masterData.dailySchedule}
        dailyPanchangam={masterData.dailyPanchangam}
        dailyNews={masterData.dailyNews}
        allEvents={masterData.allEvents}
      />
      <Sportlight spotlight={masterData.spotlight} />
      <AboutTemple />
      <Services services={masterData.services} />
      <Gallery gallery={gallery} />
      <Templetime />
      {/* <Yogaclass /> */}
      <FloatingSidemenu
        liveStreamingUrlData={masterData.liveStreamingUrlData}
      />
    </>
  );
}

export default Home;
