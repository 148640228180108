import React from "react";
import "./newsevents.css";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";

function Events({ allEvents }) {
  return (
    <>
      <React.Fragment>
        {/* //events  */}
        <div className="text-centerh-100 pt-0 panchang">
          <div className="title-bg">
            <h2>Events</h2>
          </div>

          <div className="panchang-content2">
            <Box sx={{ p: 3, maxHeight: "400px", overflowY: "auto" }}>
              {allEvents &&
                allEvents.map((event, i) => (
                  <div key={i} className="content-algn">
                    <Typography variant="p" className="news-title1">
                      {moment
                        .utc(event?.event_start)
                        .format("dddd, MMMM DD, YYYY")}
                    </Typography>
                    <Typography variant="p">
                      <div className="mb-4">
                        <div>
                          <Typography variant="p" className="news-title">
                            {event?.title}
                          </Typography>
                          <div>
                            <img
                              src={event?.image}
                              alt={event?.title}
                              className="news-title-img"
                            />
                          </div>
                          <div
                            className="news-desc"
                            dangerouslySetInnerHTML={{
                              __html: event?.description,
                            }}
                          />

                          <Link
                            className="btn text-white py-2 px-4"
                            to={`/knowmoreevents/${event.slug}`}
                          >
                            Know more
                          </Link>
                        </div>
                      </div>
                    </Typography>
                  </div>
                ))}
            </Box>
          </div>
          <div className="bottom-more">
            <Typography variant="p">
              <Link className="btn text-white py-2 px-4" to="/events">
                View all
              </Link>
            </Typography>
          </div>
        </div>
      </React.Fragment>
    </>
  );
}

export default Events;
