import React from "react";
import { Link } from "react-router-dom";
import "../../pages/prayerBooking/prayerbooking.css";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

import Grid from "@mui/material/Grid";

function Priests() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  return (
    <>
      <React.Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle id="scroll-dialog-title">
            Pandit Muralidhara Bhatta
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <img
                  src="images/priest1_img1.jpg"
                  alt="Pandit Muralidhara Bhatta"
                  className="img-responsive cursor"
                  width="100%"
                />
              </Grid>
              <Grid item xs={7}>
                <DialogContentText
                  className="pop-content"
                  id="scroll-dialog-description"
                  ref={descriptionElementRef}
                  tabIndex={-1}
                >
                  {[...new Array(1)]
                    .map(
                      () =>
                        `Pandit Muralidhara Bhatta a vedic scholar was born in Sringeri,
                      Karnataka, India in a traditional orthodox Hindu family. His study of
                      Rigveda and practice of Poorva Prayogam commenced under
                      tutelage of his father. His formal vedic training was completed in
                      Sringeri Shankara Math, Bangalore. During his undergraduate
                      years in Bangalore University, he studied Commerce.
                      Pt. Bhatta is working with Durga Temple since September 2001.
                      Pt. Bhatta also known as MuralidharaJi, is a veritable linguist. He is fluent in English,
                      Kannada, Sanskrit, Hindi, Telugu and Tamil. One of his strengths is the capability to
                      explain the significance of Hindu rituals and traditions to the people who have an
                      interest in learning about Hinduism, irrespective of their race, faith, creed and
                      background.
                      
                      Pt. Bhatta is well versed in nuances of different styles of worship used in the Indian
                      subcontinent (North Indian, South Indian, Gujraati, Punjabi etc.). You may contact him
                      to determine his availability and to determine appropriate prayer ceremony suitable for
                      your special event. He is willing to travel and can help you with all your religious needs
                      like, fire ceremonies (Havans), all types of Pujas and Homams including Chandi
                      Homam, Ganapati Homam, Rudra Homam, Navagrah Homam, Sudarshana Homam
                      etc., Satyanarayana Puja and Katha; Shiv puja—Rudrabhishekam; Devi Puja-
                      Saptashati Paath, BhagavatiSeva; all Sanskaras, and many more.

                      He is authorized to conduct Wedding ceremony in Virginia, Maryland and DC states.
                      Contact Information - 703-867-1522 (mobile) E-MAIL: mvmithra@gmail.com. `
                    )
                    .join("\n")}
                </DialogContentText>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </React.Fragment>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Priests</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Priests</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Priests</span>
          </h1>
        </div>

        <div className="row mt-5 g-4">
          <p>
            <strong>Note:</strong> Pandit Murlidhara Bhatta is from South India
            to serve the needs of our South Indian community.
            <br />
            To have Hindu religious services performed in your home or Durga
            Temple, please contact the temple at 703-690-9355 with any of the
            aforementioned priests.
          </p>
          <div className="col-lg-3 col-md-6">
            <div
              onClick={handleClickOpen("paper")}
              className="service-item d-block rounded text-center h-100 p-3"
            >
              <img
                onClick={handleClickOpen("paper")}
                className="img-fluid rounded mb-3 cursor"
                src="./images/priest1_img1.jpg"
                alt=""
              />
              <h4 className="mb-0 cursor" onClick={handleClickOpen("paper")}>
                Pandit Muralidhara Bhatta
              </h4>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              onClick={handleClickOpen("paper")}
              className="service-item d-block rounded text-center h-100 p-3"
            >
              <img
                onClick={handleClickOpen("paper")}
                className="img-fluid rounded mb-3"
                src="./images/priest1_img2.jpg"
                alt=""
              />
              <h4 className="mb-0">Shastri Omkar Sharma</h4>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              onClick={handleClickOpen("paper")}
              className="service-item d-block rounded text-center h-100 p-3"
            >
              <img
                onClick={handleClickOpen("paper")}
                className="img-fluid rounded mb-3"
                src="./images/priest1_img3.jpg"
                alt=""
              />
              <h4 className="mb-0">Acharya Shanti Swaroop Vashisht</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Priests;
