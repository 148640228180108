import React from "react";
import { Link } from "react-router-dom";
import "./priestbooking.css";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled } from "@mui/system";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";

const names = [
  "Abhishekam",
  "Annapraasanam",
  "Bhoomi Puja",
  "Gruha Pravesam",
  "Homam",
  "Naamakaranam",
  "Navagraha Homam",
  "Punyaahavaachanam",
  "Sashtiabdapoorty",
  "Sataabhishekam",
  "Satyanarayana Puja",
  "Shraddham",
  "Varalakshmi Puja",
  "Seemantham",
  "Sudarsana Homam",
  "Upanayanam",
  "Vidyaarambam",
  "Car Puja",
  "Wedding Ceremony",
  "Other",
];

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border:1px solid #ccc;
           }  
  `
);

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));
function PriestBooking() {
  const [languages, setLanguages] = React.useState("");

  const [value, setValue] = React.useState("female");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [personName, setPersonName] = React.useState([]);
  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPersonName(value);
  };
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Priest Booking</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Priest Booking</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Priest Booking</span>
          </h1>
        </div>

        <div className="row mb-5">
          <Grid
            container
            spacing={3}
            sx={{
              p: 2,
              margin: "auto",
              maxWidth: 900,
              flexGrow: 1,
              backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? "#1A2027" : "#fff",
            }}
          >
            <FormGrid item xs={12} md={6}>
              <FormControl>
                <InputLabel shrink htmlFor="select-multiple-native">
                  Puja Name
                </InputLabel>
                <Select
                  className="puja-select"
                  multiple
                  native
                  value={personName}
                  onChange={handleChangeMultiple}
                  label="Puja Name"
                  inputProps={{
                    id: "select-multiple-native",
                  }}
                >
                  {names.map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </FormGrid>
            <FormGrid item xs={12} md={6}>
              <FormControl>
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  className="label-text"
                >
                  Puja Venue
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    className="radio_btn"
                    value="At Temple"
                    control={<Radio />}
                    label="At Temple"
                  />
                  <FormControlLabel
                    value="Outside Temple"
                    control={<Radio />}
                    label="Outside Temple"
                  />
                </RadioGroup>
              </FormControl>
            </FormGrid>

            <FormGrid item xs={12} md={6}>
              <FormLabel htmlFor="first-name" className="label-text">
                Puja Date
              </FormLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker label="Select Date" />
                </DemoContainer>
              </LocalizationProvider>
            </FormGrid>
            <FormGrid item xs={12} md={6}>
              <FormLabel htmlFor="first-name" className="label-text">
                Puja Time
              </FormLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <TimePicker label="Time" />
                </DemoContainer>
              </LocalizationProvider>
            </FormGrid>

            <FormGrid item xs={12} md={6}>
              <FormLabel htmlFor="first-name" className="label-text">
                Check Availability
              </FormLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker label="Availability" />
                </DemoContainer>
              </LocalizationProvider>
            </FormGrid>

            <FormGrid item xs={12} md={6}>
              <FormLabel htmlFor="first-name" className="label-text">
                Prefered Languages
              </FormLabel>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Prefered Languages
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={languages}
                    label="Prefered Languages"
                    onChange={handleChange}
                  >
                    <MenuItem value={1}>English</MenuItem>
                    <MenuItem value={2}>Hindi</MenuItem>
                    <MenuItem value={3}>Malayalam</MenuItem>
                    <MenuItem value={4}>Tamil</MenuItem>
                    <MenuItem value={5}>Telugu</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </FormGrid>

            <FormGrid item xs={12} md={6}>
              <FormLabel htmlFor="first-name" required className="label-text">
                First name
              </FormLabel>
              <OutlinedInput
                id="first-name"
                name="first-name"
                type="name"
                placeholder="first name"
                autoComplete="first name"
                required
              />
            </FormGrid>
            <FormGrid item xs={12} md={6}>
              <FormLabel htmlFor="last-name" required className="label-text">
                Last name
              </FormLabel>
              <OutlinedInput
                id="last-name"
                name="last-name"
                type="last-name"
                placeholder="last name"
                autoComplete="last name"
                required
              />
            </FormGrid>
            <FormGrid item xs={12}>
              <FormLabel htmlFor="address1" className="label-text">
                Address line 1
              </FormLabel>
              <OutlinedInput
                id="address1"
                name="address1"
                type="address1"
                placeholder="Address line 1"
                autoComplete="address-line1"
                required
              />
            </FormGrid>
            <FormGrid item xs={12}>
              <FormLabel htmlFor="address2" className="label-text">
                Address line 2
              </FormLabel>
              <OutlinedInput
                id="address2"
                name="address2"
                type="address2"
                placeholder="Apartment, suite, unit, etc."
                autoComplete="address-line2"
                required
              />
            </FormGrid>
            <FormGrid item xs={4}>
              <FormLabel htmlFor="city" required className="label-text">
                City
              </FormLabel>
              <OutlinedInput
                id="city"
                name="city"
                type="city"
                placeholder="City"
                autoComplete="City"
                required
              />
            </FormGrid>
            <FormGrid item xs={4}>
              <FormLabel htmlFor="state" className="label-text">
                State
              </FormLabel>
              <OutlinedInput
                id="state"
                name="state"
                type="state"
                placeholder="State"
                autoComplete="State"
                required
              />
            </FormGrid>
            <FormGrid item xs={4}>
              <FormLabel htmlFor="zip" className="label-text">
                Zip
              </FormLabel>
              <OutlinedInput
                id="zip"
                name="zip"
                type="zip"
                placeholder="Zip"
                autoComplete="postal-code"
                required
              />
            </FormGrid>
            <FormGrid item xs={6}>
              <FormLabel htmlFor="phone" required className="label-text">
                Phone
              </FormLabel>
              <OutlinedInput
                id="phone"
                name="phone"
                type="phone"
                placeholder="Phone"
                autoComplete="phone"
                required
              />
            </FormGrid>
            <FormGrid item xs={6}>
              <FormLabel htmlFor="email" required className="label-text">
                Email
              </FormLabel>
              <OutlinedInput
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                autoComplete="email"
                required
              />
            </FormGrid>
            <FormGrid item xs={12}>
              <FormLabel htmlFor="Comments" className="label-text">
                Other Info
              </FormLabel>
              <Textarea
                aria-label="Comments"
                minRows={4}
                placeholder="Other Info"
              />
            </FormGrid>
            <FormGrid item xs={12}>
              <FormControl>
                <FormLabel htmlFor="catering" className="label-text">
                  Catering Service Required
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </FormGrid>

            <FormGrid item xs={3}>
              <Button variant="contained" className="btn btn2">
                Submit
              </Button>
            </FormGrid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default PriestBooking;
