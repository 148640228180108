import React, { useEffect, useState } from "react";
import "../../pages/religiousServices/religiousservices.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button } from "react-bootstrap";
import { getApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";
import { useSelector, useDispatch } from "react-redux";
import { updateCart } from "../../actions";
import { toast } from "react-hot-toast";
import PoojaBasket from "../../components/common/Basket/PoojaBasket";
import { Link } from "react-router-dom";
import moment from "moment";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

function ServicesOutsideTemple() {
  const [selectedDate, setSelectedDate] = useState({});
  const cartArr = useSelector((state) => state.cartArr);
  const isLoggedIn = localStorage.getItem("token");

  const dispatch = useDispatch();

  const [serviceData, setServiceData] = useState();

  const getWeeklyServicesData = async () => {
    try {
      const response = await getApi(apiEndPoints.outsideTempleServices);
      setServiceData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getWeeklyServicesData();
  }, []);

  // order puja or add to basket
  const handleOrderPuja = (item) => {
    if (!selectedDate[item._id]) {
      toast.error("Please select a date.");
      return;
    }

    const existingCartItem = cartArr.find(
      (cartItem) => cartItem._id === item._id
    );

    if (existingCartItem) {
      const updatedItem = {
        ...existingCartItem,
        type: "Outside Service",
        type_id: 2,
        page: "outsidetemple",
        quantity: 1,
        selectedDate: selectedDate[item._id],
      };
      dispatch(updateCart(updatedItem));
    } else {
      const newItem = {
        ...item,
        type: "Outside Service",
        type_id: 2,
        page: "outsidetemple",
        quantity: 1,
        selectedDate: selectedDate[item._id],
      };
      dispatch(updateCart(newItem));
    }
  };

  const { control, formState } = useForm({});

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          Outside Temple Services
        </h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/outsidetemple">Outside Temple Services</Link>
          </li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Durga Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Outside Temple Services</span>
          </h1>
        </div>

        <div className="row">
          <p>
            <strong>Please Note:</strong> The schedule may be changed due to
            special events held in temple on that day.
          </p>
          <div className="row">
            <div className="col-lg-9 col-md-8">
              <div className="row">
                {serviceData?.map((i, index) => (
                  <div key={i._id} className="col-lg-4 col-md-6">
                    <div className="religious-services-box">
                      <div className="religious-service-item d-block rounded text-center h-100 p-3">
                        <img
                          className="img-fluid rounded mb-2"
                          src={i.image}
                          alt={i.title}
                        />
                        <h4 className="mb-0 religious-services-title">
                          {i.title}
                        </h4>
                        <div className="container">
                          <div className="row d-flex justify-content-center align-items-center my-2">
                            <div className="religious-subtitle ">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label="Select a date"
                                  value={selectedDate[i._id]}
                                  onChange={(date) => {
                                    setSelectedDate((prevDates) => ({
                                      ...prevDates,
                                      [i._id]: date,
                                    }));
                                  }}
                                  shouldDisableDate={(date) => {
                                    const currentDate = moment
                                      .tz("America/New_York")
                                      .startOf("day");
                                    return date.isBefore(currentDate, "day");
                                  }}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                          <div className="row mt-3 d-flex justify-content-center align-items-center">
                            <div>
                              <Controller
                                control={control}
                                name={`items[${i._id}].location`}
                                render={({ field }) => (
                                  <FormControl key={field.value} fullWidth>
                                    <InputLabel id={`location-label-${i._id}`}>
                                      Location
                                    </InputLabel>
                                    <Select
                                      {...field}
                                      labelId={`location-label-${i._id}`}
                                      label="Location"
                                    >
                                      <MenuItem value={"0-50miles"}>
                                        0 - 50 miles
                                      </MenuItem>
                                      <MenuItem value={"50-100miles"}>
                                        50 - 100 miles
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <h2 className="religious-subtitle">
                            {/* <span>4th Saturday</span> */}
                            <Button
                              variant="warning text-white btn-slider slider-btn religious-btn"
                              onClick={() => handleOrderPuja(i)}
                            >
                              Order Puja
                            </Button>
                          </h2>
                          <h2 className="religious-subtitle">
                            <span>
                              {/* <QueryBuilderIcon className="time-icon" />
                              09:00 */}
                            </span>
                            $ {i.amount}
                          </h2>
                        </div>
                        <div
                          className="service-content-bdr truncate"
                          dangerouslySetInnerHTML={{
                            __html: i?.description,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* basket ------------------------------------------- */}
            <div className="col-lg-3 col-md-4">
              <PoojaBasket />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesOutsideTemple;
