import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Link,
  Typography,
  Table,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import { apiEndPoints } from "../../ApiServices/config";
import { postApi } from "../../ApiServices/api";
import moment from "moment";

import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

export default function BalGokulRecipt() {
  const orderId = localStorage.getItem("orderId");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const payeeDetails = orderDetails && JSON.parse(orderDetails?.payout);
  const devoteeData = JSON.parse(localStorage.getItem("devoteeData"));

  useEffect(() => {
    if (orderId) {
      getOrderDetails();
    }
  }, [orderId]);

  const animationProps = useSpring({
    to: async (next) => {
      while (processing) {
        await next({ opacity: 1, transform: "translateY(0%)" });
        await next({ opacity: 0.5, transform: "translateY(-10%)" });
        await next({ opacity: 1, transform: "translateY(0%)" });
      }
    },
    from: { opacity: 0, transform: "translateY(-20%)" },
  });

  const getOrderDetails = async () => {
    try {
      const response = await postApi(apiEndPoints.getOrderDetails, {
        order_id: orderId,
      });

      const orderStatus = response.data.data.order_status;

      if (orderStatus !== "paid") {
        setProcessing(true);
        setOrderDetails(response.data.data);
        setTimeout(getOrderDetails, 3000);
      } else {
        setProcessing(false);
        setOrderDetails(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const registerOrderDetails = async (orderData) => {
    try {
      const payload = {
        balgokul_Id: orderData?.items[0]?.registeration_no,
        description: orderData?.items[0]?.description,
        no_students: orderData?.items[0]?.no_students,

        order: orderData?._id,
        amount: orderData?.items[0]?.price,
      };
      const response = await postApi(apiEndPoints.balGokulBooking, payload);
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };
  useEffect(() => {
    if (orderDetails?.order_status === "paid") {
      registerOrderDetails(orderDetails);
    }
  }, [orderDetails]);
  return (
    <section className="w-100">
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Payment Status</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Payment Status</li>
        </ol>
      </div>

      <div className="row mb-5 ">
        <Container component="main" maxWidth="sm">
          <Box component="form" noValidate sx={{ mt: 3 }} align="center">
            {searchParams.get("success") ? (
              <>
                {orderDetails &&
                orderDetails.order_status === "paid" &&
                !processing ? (
                  <div className="container py-7">
                    <div className="text-center mb-5">
                      <h6 className="section-title text-center text-primary text-uppercase title-tl">
                        BalGokul
                      </h6>
                      <h1 className="mb-4">
                        <span className="text-secundary"> Reciept</span>
                      </h1>
                    </div>
                    <div className="row">
                      <div className="col-md-6 report-bal">
                        <h2 class="temple-title">
                          Balgokul Registration Payment Reciept
                        </h2>
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableBody>
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  Registration No.
                                </TableCell>
                                <TableCell align="right">
                                  {orderDetails?.items[0]?.registeration_no}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  Number of Students
                                </TableCell>
                                <TableCell align="right">
                                  {orderDetails?.items[0]?.no_students}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  Description
                                </TableCell>
                                <TableCell align="right">
                                  {orderDetails?.items[0]?.description}
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell component="th" scope="row">
                                  Amount
                                </TableCell>

                                <TableCell
                                  style={{ fontWeight: 600 }}
                                  align="right"
                                >
                                  <Typography>
                                    ${orderDetails?.items[0]?.price}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className="mt-2 text-info"
                    >
                      Payment in Progress ...
                    </FormLabel>
                    <Typography variant="body1" className="mt-2" align="center">
                      Please wait while we process your payment. Don't press
                      back or refresh the page while processing payment.
                    </Typography>
                    <animated.div style={animationProps}>
                      <Typography
                        variant="body1"
                        className="mt-2"
                        align="center"
                      >
                        Processing...
                      </Typography>
                    </animated.div>
                  </React.Fragment>
                )}
              </>
            ) : (
              <>
                <React.Fragment>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    className="mt-2 text-danger"
                  >
                    Payment Unsuccessful!
                  </FormLabel>
                  <Typography variant="body1" className="mt-2" align="center">
                    Your payment was unsuccessful. Please try again.
                  </Typography>
                </React.Fragment>
                {!processing && (
                  <Grid
                    container
                    justifyContent="center"
                    className="btn-align mt-4"
                  >
                    <Button
                      className="cont-btn"
                      variant="contained"
                      onClick={() => navigate("/")}
                    >
                      Back to Home
                    </Button>
                  </Grid>
                )}
              </>
            )}

            <CssBaseline />
            <Box sx={{ marginTop: 2 }}></Box>
          </Box>
        </Container>
      </div>
    </section>
  );
}
