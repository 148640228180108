import React from "react";
import "./header.css";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";
import ShowLogin from "./ShowLogin";
import { Link } from "react-router-dom";

function Topheader() {
  return (
    <>
      <div className="container-fluid top-bg px-5 d-none d-lg-block">
        <div className="container">
          <div className="row gx-0 align-items-center">
            <div className="col-lg-8 text-center text-lg-start mb-lg-0">
              <div className="d-flex flex-wrap">
                <Link to="/" className="text-light me-4">
                  <LocationOnIcon className="top-icon" /> 8400 Durga Place,
                  Fairfax Station, VA, 22039
                </Link>
                <Link to="/" className="text-light me-0">
                  <MailIcon className="top-icon" /> contact@durgatemple.org
                </Link>
              </div>
            </div>
            <div className="col-lg-4 text-center text-lg-end">
              <ShowLogin />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Topheader;
