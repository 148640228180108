import * as React from "react";
import "./tvgallery.css";
import Carousel from "react-bootstrap/Carousel";

function Tvgallery() {
  return (
    <>
      <Carousel data-bs-theme="dark">
        <Carousel.Item className="tvgallery-bg1">
          <img
            className="d-block slider-images"
            src="./images/tvgallery_img1.jpg"
            alt="First slide"
          />
          <Carousel.Caption className="slider-text">
            <h5 className="h5_gallery">Kalash Puja</h5>
            <ul className="tvgallery-list">
              <li>
                <span>Date : </span>Thursday, 9th May 2024
              </li>
              <li>
                <span>Time : </span>5:00 PM
              </li>
              <li>
                <span>Venue : </span>Prayer Hall(Upstairs)
              </li>
            </ul>
            <p className="gallery-p">
              Place a Kalasha filled with Gangajal inside the plate and fill it
              with coins, supari, and akshat (raw rice and turmeric powder).
              Cover it with five mango leaves and a coconut.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="tvgallery-bg2">
          <img
            className="d-block slider-images"
            src="./images/tvgallery_img2.jpg"
            alt="Second slide"
          />
          <Carousel.Caption className="slider-text">
            <h5 className="h5_gallery">Shobha Yatra!</h5>
            <ul className="tvgallery-list">
              <li>
                <span>Date : </span>Friday, 10th May 2024
              </li>
              <li>
                <span>Time : </span>5:00 PM
              </li>
              <li>
                <span>Venue : </span>Prayer Hall(Upstairs)
              </li>
            </ul>
            <p className="gallery-p">
              Join us on Friday, 10th May 2024, for a vibrant showcase of Durga
              Temple's spirit and tradition at the Shobha Yatra. This colorful
              procession is a testament to the rich cultural tapestry of our
              community.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
}

export default Tvgallery;
