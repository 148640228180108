import * as React from "react";
import "./prasadam.css";
import { Link } from "react-router-dom";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
//import ListSubheader from "@mui/material/ListSubheader";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button } from "react-bootstrap";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { DialogTitle } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Catering() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Product detail
          </DialogTitle>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <img
                src="./images/category_imgs1.jpg"
                alt="Badam Burfi"
                className="img-responsive cursor"
                width="100%"
              />
            </Grid>
            <Grid item xs={7}>
              <Typography gutterBottom variant="h2" className="h2-ttl">
                Badam Burfi
              </Typography>

              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <Typography gutterBottom variant="h2" className="h3-ttl3">
                $ 2.00
              </Typography>
              <Typography gutterBottom variant="h2" className="h3-ttl">
                Weight
              </Typography>
              <ul className="quantity mb-2">
                <li>
                  <Link to="/">250 gms</Link>
                </li>
                <li>
                  <Link to="/">500 gms</Link>
                </li>
                <li>
                  <Link to="/">1000 gms</Link>
                </li>
              </ul>
              <DialogContent>
                <Typography gutterBottom variant="h5" component="div">
                  Description
                </Typography>
                <Typography gutterBottom>
                  Badam burfi is an exotic sweet recipe made using badam, sugar,
                  ghee, and little milk as the main ingredients. Almond burfi is
                  a very simple and easy sweet...
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button className="price-btn" onClick={handleClose}>
                  + Add to Cart
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </BootstrapDialog>
      </React.Fragment>

      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Catering</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Catering</li>
        </ol>
      </div>
      <div className="container py-5">
        {/* <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Durga Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Kitchen</span>
          </h1>
        </div> */}
        <div className="row mb-5">
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <FormControl sx={{ m: 1, minWidth: "100%" }}>
              <InputLabel htmlFor="grouped-native-select">
                Select Food Category
              </InputLabel>
              <Select
                native
                defaultValue=""
                id="grouped-native-select"
                label="Select Food Category"
              >
                <option aria-label="None" value="" />
                <option value={1}>Sweet</option>
                <option value={2}>Snack</option>
                <option value={3}>Tiffin</option>
                <option value={4}>Rice</option>
                <option value={5}>South Indian</option>
                <option value={6}>North Indian</option>
                <option value={7}>Pickles / Pachidi</option>
                <option value={8}>Beverages</option>
                <option value={9}>Greens</option>
                <option value={10}>Others</option>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <FormControl sx={{ m: 1, minWidth: "100%" }}>
              <InputLabel htmlFor="grouped-select">Select Food</InputLabel>
              <Select defaultValue="" id="grouped-select" label="Select Food">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={1}>Badam Burfi</MenuItem>
                <MenuItem value={2}>Ghee Mysore Pak</MenuItem>
                <MenuItem value={3}>Parappu Tengai 10" - Pair</MenuItem>
                <MenuItem value={4}>Parappu Tengai 8" - Pair</MenuItem>
                <MenuItem value={5}>Boondi Laddu</MenuItem>
                <MenuItem value={6}>Coconut Burfi</MenuItem>
                <MenuItem value={7}>Gulab Jamun</MenuItem>
                <MenuItem value={8}>Purnam Burelu</MenuItem>
                <MenuItem value={9}>Rava Laddu</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="box-bg">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-2 col-xs-12">
              <img
                onClick={handleClickOpen}
                src="./images/category_imgs1.jpg"
                alt="category"
                className="img-responsive cursor"
                width="100%"
              />
            </div>
            <div className="col-lg-6 col-md-5 col-sm-5 col-xs-12">
              <h4 className="catering-ttl cursor" onClick={handleClickOpen}>
                Badam Burfi
              </h4>
              <p className="catering-cont">
                Badam burfi is an exotic sweet recipe made using badam, sugar,
                ghee, and little milk as the main ingredients. Almond burfi is a
                very simple and easy sweet...
                <span
                  className="prasad-more"
                  variant="outlined"
                  onClick={handleClickOpen}
                >
                  [More]
                </span>
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <div className="d-flex justify-content-between">
                <div>
                  <ul className="quantity">
                    <li>
                      <Link to="/">250 gms</Link>
                    </li>
                    <li>
                      <Link to="/">500 gms</Link>
                    </li>
                    <li>
                      <Link to="/">1000 gms</Link>
                    </li>
                  </ul>
                </div>
                <div className="text-rght">
                  <p className="tray">
                    Each Price <span>$2.00</span>
                  </p>
                  <Button className="price-btn">Add</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-bg">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-2 col-xs-12">
              <img
                onClick={handleClickOpen}
                src="./images/category_imgs2.jpg"
                alt="category"
                className="img-responsive cursor"
                width="100%"
              />
            </div>
            <div className="col-lg-6 col-md-5 col-sm-5 col-xs-12">
              <h4 className="catering-ttl cursor" onClick={handleClickOpen}>
                Ghee Mysore Pak
              </h4>
              <p className="catering-cont">
                Mysore Pak is made with Ghee, sugar, and gram flour. Adding
                Cardamom powder will give fulfillment in taste. The making of
                mysore pak starts with boiling the sugar in water to get a
                sugary syrup...
                <span
                  className="prasad-more"
                  variant="outlined"
                  onClick={handleClickOpen}
                >
                  [More]
                </span>
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <div className="d-flex justify-content-between">
                <div>
                  <ul className="quantity">
                    <li>
                      <Link to="/">250 gms</Link>
                    </li>
                    <li>
                      <Link to="/">500 gms</Link>
                    </li>
                    <li>
                      <Link to="/">1000 gms</Link>
                    </li>
                  </ul>
                </div>
                <div className="text-rght">
                  <p className="tray">
                    Each Price <span>$2.00</span>
                  </p>
                  <Button className="price-btn">Add</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-bg">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-2 col-xs-12">
              <img
                onClick={handleClickOpen}
                src="./images/category_img2.jpg"
                alt="category"
                className="img-responsive cursor"
                width="100%"
              />
            </div>
            <div className="col-lg-6 col-md-5 col-sm-5 col-xs-12">
              <h4 className="catering-ttl cursor" onClick={handleClickOpen}>
                Appi Payasam
              </h4>
              <p className="catering-cont">
                Appe are usually prepared as small pooris and later broken into
                small bite-sizes. Appe kheer is a ultra creamy and lip smacking
                kheer prepared with milk, appe and finally nuts are added to it
                to add crunchiness to it.{" "}
                <span
                  className="prasad-more"
                  variant="outlined"
                  onClick={handleClickOpen}
                >
                  [More]
                </span>
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <div className="d-flex justify-content-between">
                <p className="tray">
                  Small Tray <span>$75.00</span>
                </p>
                <p className="tray">
                  Medium Tray <span>$100.00</span>
                </p>
                <p className="tray">
                  Large Tray <span>$150.00</span>
                  <Button className="price-btn">Add</Button>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="box-bg">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-2 col-xs-12">
              <img
                onClick={handleClickOpen}
                src="./images/category_img3.jpg"
                alt="category"
                className="img-responsive cursor"
                width="100%"
              />
            </div>
            <div className="col-lg-6 col-md-5 col-sm-5 col-xs-12">
              <h4 className="catering-ttl cursor" onClick={handleClickOpen}>
                Channa Dal Payasam
              </h4>
              <p className="catering-cont">
                Chana dal payasam is a traditional dessert prepared during
                festivals like Ugadi, Ram Navami, Diwali, Navratri,
                Varamahalakshmi vratam, etc. It is first offered in the form of
                Naivedyam and then distributed as a dessert.{" "}
                <span
                  className="prasad-more"
                  variant="outlined"
                  onClick={handleClickOpen}
                >
                  [More]
                </span>
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <div className="d-flex justify-content-between">
                <p className="tray">
                  Small Tray <span>$75.00</span>
                </p>
                <p className="tray">
                  Medium Tray <span>$100.00</span>
                </p>
                <p className="tray">
                  Large Tray <span>$150.00</span>
                  <Button className="price-btn">Add</Button>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="box-bg">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-2 col-xs-12">
              <img
                src="./images/category_img4.jpg"
                alt="category"
                className="img-responsive cursor"
                width="100%"
              />
            </div>
            <div className="col-lg-6 col-md-5 col-sm-5 col-xs-12">
              <h4 className="catering-ttl cursor">Fruit Custard</h4>
              <p className="catering-cont">
                Fruit custard is a delightfully creamy and rich chilled dessert
                made with custard powder, milk, fruits, and a variety of nuts if
                desired. You can choose to add any of your favourite fruits.{" "}
                <span
                  className="prasad-more"
                  variant="outlined"
                  onClick={handleClickOpen}
                >
                  [More]
                </span>
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <div className="d-flex justify-content-between">
                <p className="tray">
                  Small Tray <span>$75.00</span>
                </p>
                <p className="tray">
                  Medium Tray <span>$100.00</span>
                </p>
                <p className="tray">
                  Large Tray <span>$150.00</span>
                  <Button className="price-btn">Add</Button>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="box-bg">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-2 col-xs-12">
              <img
                onClick={handleClickOpen}
                src="./images/category_img5.jpg"
                alt="category"
                className="img-responsive cursor"
                width="100%"
              />
            </div>
            <div className="col-lg-6 col-md-5 col-sm-5 col-xs-12">
              <h4 className="catering-ttl cursor" onClick={handleClickOpen}>
                Mango Kesari
              </h4>
              <p className="catering-cont">
                Kesari is traditionally made by mixing of rava , sugar and
                ghee.Kesari is the major and traditional sweet that is made
                during festivals and auspicious days. In mampazha Kesari there
                is addition of mango pulp to the normal kesari. It gives the
                complete taste of mangoes in the sheera.{" "}
                <span
                  className="prasad-more"
                  variant="outlined"
                  onClick={handleClickOpen}
                >
                  [More]
                </span>
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <div className="d-flex justify-content-between">
                <p className="tray">
                  Small Tray <span>$75.00</span>
                </p>
                <p className="tray">
                  Medium Tray <span>$100.00</span>
                </p>
                <p className="tray">
                  Large Tray <span>$150.00</span>
                  <Button className="price-btn">Add</Button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Catering;
