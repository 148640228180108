import { useState } from "react";
import { postApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";

function useLoadMasterDataAnnualServices() {
  const [masterData, setMasterData] = useState();
  const slug = "annual";

  const getMasterData = async () => {
    const response = await postApi(apiEndPoints.weeklyServices, { slug });
    if (response && response.status === 200) {
      setMasterData(response?.data?.data);
    }
  };

  return {
    masterData,
    getMasterData,
    setMasterData,
  };
}

export default useLoadMasterDataAnnualServices;
