import React from "react";
import "./dayschedule.css";

function Dayschedule({ dailySchedule }) {
  return (
    <>
      <ul className="dayschedulel-list">
        {dailySchedule?.map((Schedule, i) => (
          <li key={i}>
            <span>{Schedule.time}</span>
            {Schedule.title}
          </li>
        ))}
      </ul>
    </>
  );
}

export default Dayschedule;
