import { useState } from "react";
import { getApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";

function useLoadMasterDataAllDonation() {
  const [masterData, setMasterData] = useState();

  const getMasterData = async () => {
    const response = await getApi(apiEndPoints.getAllDonations);
    if (response && response.status === 200) {
      setMasterData(response?.data?.data);
    }
  };

  return {
    masterData,
    getMasterData,
    setMasterData,
  };
}

export default useLoadMasterDataAllDonation;
