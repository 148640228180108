import React from "react";
import "./footer.css";
import Copyrights from "./Copyrights";
import NewsLetter from "../../../pages/newsLetter/NewsLetter";
import { Button, TextField } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import PinterestIcon from "@mui/icons-material/Pinterest";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";
import { apiEndPoints } from "../../../ApiServices/config";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  const checkLoggedIn = async () => {
    const isLoggedIn = localStorage.getItem("token");
    if (isLoggedIn) {
      navigate("/volunteer");
    } else {
      toast.error("Please Login First");
    }
  };

  const commingSoon = async () => {
    toast.success("This feature will available soon");
  };

  return (
    <>
      <div className="container-fluid footer py-4">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-md-6 col-lg-6 col-xl-3">
              <NewsLetter />
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3">
              <div className="footer-item d-flex flex-column">
                <h4 className="mb-4">Information</h4>
                <Link to="/">
                  <KeyboardArrowRightIcon /> Home
                </Link>
                <Link to="/servicesattemple">
                  <KeyboardArrowRightIcon /> Services
                </Link>
                <Link to="/donation">
                  <KeyboardArrowRightIcon /> Donation
                </Link>
                <Link to="/events">
                  <KeyboardArrowRightIcon /> Events & Activities
                </Link>
                <Link to="/location">
                  <KeyboardArrowRightIcon /> Location Directions
                </Link>
                <Link to="/templehistory">
                  <KeyboardArrowRightIcon /> About Us
                </Link>
                <Link to="/contactus">
                  <KeyboardArrowRightIcon /> Contact Us
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3">
              <div className="footer-item d-flex flex-column">
                <h4 className="mb-4">Others</h4>
                <Link to="/faq">
                  <KeyboardArrowRightIcon /> FAQ's
                </Link>
                <Link to="/gallery/photos">
                  <KeyboardArrowRightIcon /> Photo Gallery
                </Link>
                <Link to="/gallery/videos">
                  <KeyboardArrowRightIcon /> Video Gallery
                </Link>
                <Link to="/">
                  <KeyboardArrowRightIcon /> Sitemap
                </Link>
                <span className="cursor-pointer" onClick={checkLoggedIn}>
                  <KeyboardArrowRightIcon /> Volunteer Registration
                </span>
                <span className="cursor-pointer mt-1" onClick={commingSoon}>
                  <KeyboardArrowRightIcon /> Articles of Inc & Bylaws
                </span>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3">
              <div className="footer-item d-flex flex-column">
                <h4 className="mb-4">Contact Info</h4>
                <Link to="/">
                  <LocationOnIcon className="footer-icon" /> 8400 Durga Place,
                  Fairfax Station, VA, 22039
                </Link>
                <a href="mailto:contact@durgatemple.org">
                  <MailOutlineIcon className="footer-icon" />{" "}
                  contact@durgatemple.org
                </a>
                <Link to="/">
                  <PhoneIcon className="footer-icon" /> 703-690-9355
                </Link>
                <div>
                  <h4 className="mt-4">Website issues :</h4>
                  <a href="mailto:dt_website_support@durgatemple.org ">
                    <MailOutlineIcon className="footer-icon" />{" "}
                    dt_website_support@durgatemple.org
                  </a>
                </div>
                <div>
                  <h4 className="mt-4">Follow Us</h4>
                  <ul className="footer-icon-list">
                    <li>
                      <a
                        href="https://www.facebook.com/DurgaTemple"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <FacebookIcon />
                      </a>
                    </li>
                    {/* <li>
                      <Link to="/">
                        <TwitterIcon />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <PinterestIcon />
                      </Link>
                    </li> */}
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.youtube.com/@DurgaTempleLive/streams"
                      >
                        <YouTubeIcon />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/durgatemple/"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <InstagramIcon />
                      </a>
                    </li>
                    {/* <li>
                      <Link to="/">
                        <PhoneIcon />
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Copyrights />
    </>
  );
}
export default Footer;
