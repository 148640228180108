import React from "react";
import { useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import { apiEndPoints } from "../../ApiServices/config";
import { postApi } from "../../ApiServices/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function Newsletter() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      data.subscribeBy = "footer";
      const response = await postApi(apiEndPoints.subscribe, data);
      if (response?.data?.status === 0) {
        toast.error(response?.data?.message);
      } else if (response?.data?.status === 1) {
        toast.success(response?.data?.message);
        navigate("/otp", { state: { data } });
        reset();
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      toast.error(`Registration failed: ${error}`);
    }
  };

  return (
    <div className="footer-item d-flex flex-column">
      <h4 className="mb-4">Newsletter</h4>
      <p>
        Please register your email address to be connected with temple and
        receive regular newsletters and events.
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          className="input-field mb-2b"
          id="first_name"
          label="First Name"
          variant="outlined"
          {...register("first_name", { required: "First Name is required" })}
          error={!!errors.first_name}
          helperText={errors.first_name?.message}
        />
        <TextField
          className="input-field mb-2b"
          id="last_name"
          label="Last Name"
          variant="outlined"
          {...register("last_name", { required: "Last Name is required" })}
          error={!!errors.last_name}
          helperText={errors.last_name?.message}
        />
        <TextField
          className="input-field mb-2b"
          id="email"
          label="Email"
          variant="outlined"
          {...register("email", {
            required: "Email is required",
            pattern: { value: /^\S+@\S+$/i, message: "Invalid email" },
          })}
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        <TextField
          className="input-field mb-2b"
          id="contact_number"
          label="Phone"
          variant="outlined"
          {...register("contact_number", {
            required: "Phone number is required",
          })}
          error={!!errors.contact_number}
          helperText={errors.contact_number?.message}
        />
        <Button
          className="footer-btn py-3 px-5"
          variant="contained"
          type="submit"
        >
          Submit
        </Button>
      </form>
    </div>
  );
}

export default Newsletter;
