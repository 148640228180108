import React from "react";
import { Link } from "react-router-dom";
import "./floatingsidemenu.css";
import VideocamIcon from "@mui/icons-material/Videocam";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EventNoteIcon from "@mui/icons-material/EventNote";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

function FloatingSidemenu(props) {
  const { liveStreamingUrlData } = props;

  return (
    <>
      <div className="sidenav">
        <a
          href={liveStreamingUrlData && liveStreamingUrlData.url}
          className="sidenav_bg1"
          target="_blank"
          rel="noreferrer"
        >
          <VideocamIcon />
        </a>
        <a
          href="https://www.google.com/maps/dir//Durga+Temple+of+Virginia,+8400+Durga+Pl,+Fairfax+Station,+VA+22039/@38.728611,-77.251111,16z/data=!4m9!4m8!1m0!1m5!1m1!1s0x89b653a345b698d3:0x720b5540b8255ac9!2m2!1d-77.2511111!2d38.7286111!3e0?hl=en-US&entry=ttu"
          className="sidenav_bg2"
        >
          <LocationOnIcon />
        </a>
        <Link to="/expressbookings" className="sidenav_bg3">
          <EventNoteIcon />
        </Link>
        <Link to="/events" className="sidenav_bg4">
          <TextSnippetIcon />
        </Link>
      </div>
    </>
  );
}

export default FloatingSidemenu;
