import React, { useEffect, useState } from "react";
import "../../../pages/religiousServices/religiousservices.css";
import { Button } from "react-bootstrap";
import moment from "moment";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import { updateCart, removeFromCart } from "../../../actions";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { MenuItem, Select, TextField } from "@mui/material";
import { getApi } from "../../../ApiServices/api";
import { apiEndPoints } from "../../../ApiServices/config";
import { toast } from "react-hot-toast";

import ConsentDialog from "../ConsentDialog";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function PoojaBasket() {
  const navigate = useNavigate();
  const [makeDonationChecked, setMakeDonationChecked] = useState(false);
  const cartArr = useSelector((state) => state.cartArr);
  const [totalAmount, setTotalAmount] = useState(0);
  const [expanded, setExpanded] = React.useState({});
  const [donationData, setDonationsData] = useState();
  const [donationItem, setDonationItem] = useState();
  const [donationAmount, setDonationAmount] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const total = cartArr.reduce(
      (totalAmount, item) => totalAmount + item.amount * (item.quantity || 1),
      0
    );
    setTotalAmount(total);
  }, [cartArr]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded((prev) => ({
      ...prev,
      [panel]: newExpanded ? panel : false,
    }));
  };

  // Empty Pooja Basket
  const handleEmptyPoojaBasket = () => {
    dispatch(updateCart());
  };

  const handleProceedToPay = () => {
    navigate("/checkout-services");
    // const itemCount = cartArr.length;
    // let toastShown = false;
    // let counter = 0
    // cartArr.forEach((item) => {
    //   var cut_off_service_time = item.cut_off_service_time;
    //   var cut_off_hours = item.cut_off_hours;
    //   var parts = cut_off_service_time?.split(":");
    //   var hours = parseInt(parts[0], 10);
    //   var minutes = parseInt(parts[1], 10);
    //   var seconds = parseInt(parts[2], 10);
    //   hours -= cut_off_hours;
    //   if (hours < 0) {
    //     hours = 24 + hours;
    //   }
    //   var finalCutOff = pad(hours) + ":" + pad(minutes) + ":" + pad(seconds);
    //   function pad(num) {
    //     return (num < 10 ? "0" : "") + num;
    //   }
    //   var currentDate = new Date();
    //   var formattedCurrentDate = currentDate.toISOString().slice(0, 19) + ".000Z";
    //   var dayOfWeek = currentDate.getDay();
    //   var hours = currentDate.getHours();
    //   var minutes = currentDate.getMinutes();
    //   var seconds = currentDate.getSeconds();
    //   var currentTime = pad(hours) + ":" + pad(minutes) + ":" + pad(seconds);
    //   if (item.selectedDate > formattedCurrentDate) {
    //     counter++;
    //     if (itemCount == counter) {
    //       navigate("/checkout-services");
    //     }
    //   } else if (item.selectedDate == formattedCurrentDate && finalCutOff > currentTime) {
    //     counter++;
    //     if (itemCount == counter) {
    //       navigate("/checkout-services");
    //     }
    //   }
    //   else {
    //     if (!toastShown) {
    //       toast.error("Cut Off time got passed, So you can't book any pooja.");
    //       toastShown = true;
    //     }
    //   }
    // });
  };

  const getAllDonations = async () => {
    const response = await getApi(apiEndPoints.getAllDonations);
    if (response && response.status === 200) {
      setDonationsData(response?.data?.data);
      setDonationItem(response?.data?.data[0]);
    }
  };

  useEffect(() => {
    getAllDonations();
  }, []);

  const handleAddDonations = () => {
    if (Number(donationAmount) === 0) {
      toast.error("Donation amount must be greater than 0");
    } else {
      const existingCartItem = cartArr.find(
        (cartItem) => cartItem._id === donationItem._id
      );

      if (existingCartItem) {
        const updatedItem = {
          ...existingCartItem,
          amount: donationAmount,
          type: "Donations",
          type_id: 5,
          location: "",
          page: "services/donation",
        };
        dispatch(updateCart(updatedItem));
        toast.success("Your puja/donation is updated");
        setDonationAmount(0);
        setDonationItem(donationData[0]);
        setMakeDonationChecked(false);
      } else {
        const newItem = {
          ...donationItem,
          amount: donationAmount,
          type: "Donations",
          type_id: 5,
          location: "",
          page: "services/donation",
        };
        dispatch(updateCart(newItem));

        setDonationAmount(0);
        setDonationItem(donationData[0]);
        setMakeDonationChecked(false);
      }
    }
  };

  const handleChildrenRemove = (item) => {
    dispatch(removeFromCart(item._id));
    toast.success("Item removed from the Basket");
  };

  return (
    <div>
      <div className="border-bottom mb-4 pb-4 details-bg religious-service-product">
        <div className="d-flex justify-content-between mt-4">
          <h5 className="font-weight-semi-bold mb-3">Details</h5>
          <div className="empty-btn" onClick={handleEmptyPoojaBasket}>
            Empty Puja Basket
          </div>
        </div>
        <div className="custom-control custom-checkbox mb-3">
          <>
            {cartArr.length > 0 &&
              cartArr?.map((item, index) => (
                <>
                  <Accordion
                    expanded={
                      expanded[item._id] == undefined
                        ? true
                        : expanded[item._id]
                    }
                    onChange={handleChange(item._id)}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      <div className="row">
                        <div className="col-md-10">
                          <Typography>{item.title}</Typography>
                        </div>
                        <div className="col-md-2">
                          <ConsentDialog
                            cartItem={item}
                            onAgree={handleChildrenRemove}
                          />
                        </div>
                      </div>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Typography>
                        <ul className="detail-list">
                          <li>
                            {item?.title}
                            <span>
                              $ {item?.amount * (item?.quantity || 1)}
                            </span>
                          </li>
                          {item?.selectedDate ? (
                            <li className="mt-4">
                              Pooja Date
                              <span>
                                {moment(
                                  item?.selectedDate
                                    ? new Date(item?.selectedDate).toUTCString()
                                    : item?.date
                                )
                                  .tz("America/New_York")
                                  .format("dddd, MMMM Do YYYY")}
                              </span>
                            </li>
                          ) : (
                            <li className="mt-4">
                              Donation Date
                              <span>
                                {moment(new Date())
                                  .tz("America/New_York")
                                  .format("YYYY-MM-DD")}
                              </span>
                            </li>
                          )}
                          <li>
                            Quantity <span>{item?.quantity}</span>
                          </li>
                          <li className="service-details-book">
                            Book online - attend in person
                          </li>
                        </ul>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </>
              ))}
          </>

          <div className="d-flex align-items-center custom-control custom-checkbox mb-3">
            <input
              type="checkbox"
              checked={makeDonationChecked}
              onChange={() => setMakeDonationChecked(!makeDonationChecked)}
              className="custom-control-input custom-checkbox-input"
              id="price-2"
            />
            <label className="custom-control-label service-details-book-input">
              I would like to make a <Link to="/donation">donation</Link>
            </label>
          </div>
          {makeDonationChecked && (
            <div className="d-flex justify-content-between border-bottom mt-auto p-3 flex-column">
              <TextField
                value={donationAmount}
                onChange={(e) => setDonationAmount(e.target.value)}
                label="Donation Amount"
                id="outlined-size-normal"
              />

              <div className="d-flex justify-content-between align-items-center gap-3 my-2">
                <Select
                  labelId="demo-simple-select-label"
                  label="Select Donation Category"
                  onChange={(e) => {
                    setDonationItem(e.target.value);
                  }}
                  defaultValue={donationItem}
                >
                  {donationData?.map((item) => (
                    <MenuItem key={item._id} value={item}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
                <button
                  className="btn btn2 btn-dark rounded"
                  onClick={handleAddDonations}
                >
                  Add
                </button>
              </div>
            </div>
          )}

          <ul className="detail-list">
            <li className="service-details-total">
              Total Amount: <span>$ {totalAmount}</span>
            </li>
          </ul>
          <Button
            variant="warning text-white btn-slider slider-btn"
            onClick={handleProceedToPay}
          >
            Proceed to pay
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PoojaBasket;
