import * as React from "react";
import "./prasadam.css";
import { Link } from "react-router-dom";
import { styled } from "@mui/system";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Unstable_NumberInput as BaseNumberInput } from "@mui/base/Unstable_NumberInput";

import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

import {
  Avatar,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import { Button } from "react-bootstrap";

const blue = {
  100: "#daecff",
  200: "#b6daff",
  300: "#66b2ff",
  400: "#3399ff",
  500: "#007fff",
  600: "#0072e5",
  700: "#0059B2",
  800: "#004c99",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const StyledInputRoot = styled("div")(
  ({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[500]};
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  `
);

const StyledInput = styled("input")(
  ({ theme }) => `
    font-size: 0.875rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.375;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 4px ${
      theme.palette.mode === "dark" ? "rgba(0,0,0, 0.5)" : "rgba(0,0,0, 0.05)"
    };
    border-radius: 8px;
    margin: 0 6px;
    padding: 6px 6px;
    outline: 0;
    min-width: 0;
    width: 40px;
    text-align: center;
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === "dark" ? blue[700] : blue[200]
      };
    }
  
    &:focus-visible {
      outline: 0;
    }
  `
);

const StyledButton = styled("button")(
  ({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    line-height: 1.5;
    border: 1px solid;
    border-radius: 999px;
    border-color: ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    background: ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
    color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
    width: 24px;
    height: 24px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  
    &:hover {
      cursor: pointer;
      background: ${theme.palette.mode === "dark" ? blue[700] : blue[500]};
      border-color: ${theme.palette.mode === "dark" ? blue[500] : blue[400]};
      color: ${grey[50]};
    }
  
    &:focus-visible {
      outline: 0;
    }
  
    &.increment {
      order: 1;
    }
  `
);

const avatarSX = {
  width: 58,
  height: 58,
  marginRight: 2,
};

const actionSX = {
  mt: 0.75,
  ml: 1,
  top: "auto",
  right: "auto",
  alignSelf: "flex-start",
  transform: "none",
};
function createData(name, qty, price) {
  return { name, qty, price };
}

function Cafeteria() {
  const rows = [
    createData("Idli with Chutney", 1, 1.75),
    createData("Samosa", 3, 12),
    createData("Tea", 2, 4),
  ];

  const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
    return (
      <BaseNumberInput
        slots={{
          root: StyledInputRoot,
          input: StyledInput,
          incrementButton: StyledButton,
          decrementButton: StyledButton,
        }}
        slotProps={{
          incrementButton: {
            children: <AddIcon fontSize="small" />,
            className: "increment",
          },
          decrementButton: {
            children: <RemoveIcon fontSize="small" />,
          },
        }}
        {...props}
        ref={ref}
      />
    );
  });

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Cafeteria</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Cafeteria</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Durga Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Cafeteria</span>
          </h1>
        </div>
        <div className="row">
          <div className="col-lg-8 col-md-8">
            <div className="row mt-4">
              <div className="col-lg-6 col-md-6">
                <Grid item xs={12} md={5} lg={4}>
                  <Card>
                    <h2 className="cafeteria-title">Tiffin</h2>
                    <List
                      component="nav"
                      sx={{
                        px: 0,
                        py: 0,
                        "& .MuiListItemButton-root": {
                          py: 1.5,
                          "& .MuiAvatar-root": avatarSX,
                          "& .MuiListItemSecondaryAction-root": {
                            ...actionSX,
                            position: "relative",
                          },
                        },
                      }}
                    >
                      <ListItemButton divider>
                        <ListItemAvatar>
                          <Avatar
                            alt="Puri Channa"
                            src="./images/cafeteria_img1.jpg"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1">
                              Puri Channa
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          <Stack alignItems="flex-end">
                            <Typography variant="h6" color="secondary" noWrap>
                              $8.99
                            </Typography>
                          </Stack>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                      <ListItemButton divider>
                        <ListItemAvatar>
                          <Avatar
                            alt="Idli with Chutney"
                            src="./images/cafeteria_img2.jpg"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1">
                              Idli with Chutney
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          <Stack alignItems="flex-end">
                            <Typography variant="h6" color="secondary" noWrap>
                              $1.75
                            </Typography>
                          </Stack>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                    </List>
                  </Card>
                </Grid>
                <Grid item xs={12} md={5} lg={4} className="mt-5">
                  <Card>
                    <h2 className="cafeteria-title">Snack</h2>
                    <List
                      component="nav"
                      sx={{
                        px: 0,
                        py: 0,
                        "& .MuiListItemButton-root": {
                          py: 1.5,
                          "& .MuiAvatar-root": avatarSX,
                          "& .MuiListItemSecondaryAction-root": {
                            ...actionSX,
                            position: "relative",
                          },
                        },
                      }}
                    >
                      <ListItemButton divider>
                        <ListItemAvatar>
                          <Avatar
                            alt="mixure"
                            src="./images/cafeteria_img5.jpg"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1">Mixure</Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          <Stack alignItems="flex-end">
                            <Typography variant="h6" color="secondary" noWrap>
                              $4.00
                            </Typography>
                          </Stack>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                      <ListItemButton divider>
                        <ListItemAvatar>
                          <Avatar
                            alt="Samosa"
                            src="./images/cafeteria_img6.jpg"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1">Samosa</Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          <Stack alignItems="flex-end">
                            <Typography variant="h6" color="secondary" noWrap>
                              $2.00
                            </Typography>
                          </Stack>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                    </List>
                  </Card>
                </Grid>
                <Grid item xs={12} md={5} lg={4} className="mt-5">
                  <Card>
                    <h2 className="cafeteria-title">Snack</h2>
                    <List
                      component="nav"
                      sx={{
                        px: 0,
                        py: 0,
                        "& .MuiListItemButton-root": {
                          py: 1.5,
                          "& .MuiAvatar-root": avatarSX,
                          "& .MuiListItemSecondaryAction-root": {
                            ...actionSX,
                            position: "relative",
                          },
                        },
                      }}
                    >
                      <ListItemButton divider>
                        <ListItemAvatar>
                          <Avatar alt="Tea" src="./images/cafeteria_img7.jpg" />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1">Tea</Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          <Stack alignItems="flex-end">
                            <Typography variant="h6" color="secondary" noWrap>
                              $2.00
                            </Typography>
                          </Stack>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                      <ListItemButton divider>
                        <ListItemAvatar>
                          <Avatar
                            alt="Coffee"
                            src="./images/cafeteria_img8.jpg"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1">Coffee</Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          <Stack alignItems="flex-end">
                            <Typography variant="h6" color="secondary" noWrap>
                              $2.00
                            </Typography>
                          </Stack>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                    </List>
                  </Card>
                </Grid>
              </div>
              <div className="col-lg-6 col-md-6">
                <Grid item xs={12} md={5} lg={4}>
                  <Card>
                    <h2 className="cafeteria-title">Rice</h2>
                    <List
                      component="nav"
                      sx={{
                        px: 0,
                        py: 0,
                        "& .MuiListItemButton-root": {
                          py: 1.5,
                          "& .MuiAvatar-root": avatarSX,
                          "& .MuiListItemSecondaryAction-root": {
                            ...actionSX,
                            position: "relative",
                          },
                        },
                      }}
                    >
                      <ListItemButton divider>
                        <ListItemAvatar>
                          <Avatar
                            alt="Tamarind Rice"
                            src="./images/cafeteria_img3.jpg"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1">
                              Tamarind Rice
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          <Stack alignItems="flex-end">
                            <Typography variant="h6" color="secondary" noWrap>
                              $4.00
                            </Typography>
                          </Stack>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                      <ListItemButton divider>
                        <ListItemAvatar>
                          <Avatar
                            alt="Sambar Rice"
                            src="./images/cafeteria_img4.jpg"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1">
                              Sambar Rice
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          <Stack alignItems="flex-end">
                            <Typography variant="h6" color="secondary" noWrap>
                              $5.99
                            </Typography>
                          </Stack>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                    </List>
                  </Card>
                </Grid>
                <Grid item xs={12} md={5} lg={4} className="mt-5">
                  <Card>
                    <h2 className="cafeteria-title">Sweet</h2>
                    <List
                      component="nav"
                      sx={{
                        px: 0,
                        py: 0,
                        "& .MuiListItemButton-root": {
                          py: 1.5,
                          "& .MuiAvatar-root": avatarSX,
                          "& .MuiListItemSecondaryAction-root": {
                            ...actionSX,
                            position: "relative",
                          },
                        },
                      }}
                    >
                      <ListItemButton divider>
                        <ListItemAvatar>
                          <Avatar
                            alt="Ravva Laddu"
                            src="./images/cafeteria_img9.jpg"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1">
                              Ravva Laddu
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          <Stack alignItems="flex-end">
                            <Typography variant="h6" color="secondary" noWrap>
                              $4.00
                            </Typography>
                          </Stack>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                      <ListItemButton divider>
                        <ListItemAvatar>
                          <Avatar
                            alt="Ghee Mysore Pak"
                            src="./images/cafeteria_img10.jpg"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1">
                              Ghee Mysore Pak
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          <Stack alignItems="flex-end">
                            <Typography variant="h6" color="secondary" noWrap>
                              $2.00
                            </Typography>
                          </Stack>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                    </List>
                  </Card>
                </Grid>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="border-bottom mb-4 pb-4 details-bg religious-service-product">
              <div className="d-flex justify-content-between mt-4">
                <h5 className="font-weight-semi-bold mb-3">Details</h5>
                <div className="empty-btn">Empty Cart</div>
              </div>

              <TableContainer component={Paper}>
                <Table aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Item Name</TableCell>
                      <TableCell align="center">Qty</TableCell>
                      <TableCell align="right">$ Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">
                          <NumberInput
                            aria-label="Quantity Input"
                            min={1}
                            max={99}
                          />
                        </TableCell>
                        <TableCell align="right">${row.price}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={2} className="font-b">
                        Grand Total
                      </TableCell>
                      <TableCell align="right" className="font-b">
                        $ 17.75
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Button variant="contained" className="mt-4">
                Proceed to pay
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cafeteria;
