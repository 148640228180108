import React from "react";
import "./main.css";
import App from "./App";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";

const root = ReactDOM.createRoot(document.getElementById("root"));
ReactGA.initialize("G-2BSMC3MD4X");

ReactGA.send({ hitType: "index.js", page: "/my-path", title: "Index" });

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
    <Toaster
      position="top-center"
      gutter={8}
      toastOptions={{
        duration: 5000,
        style: {
          padding: "16px",
        },
      }}
    />
  </React.StrictMode>
);
