import React from "react";
import "./newsevents.css";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function News({ dailyNews }) {
  return (
    <>
      <React.Fragment>
        <div className="text-center h-100 pt-0 panchang">
          <div className="title-bg">
            <h2>News</h2>
          </div>

          <div className="panchang-content2">
            <Box
              sx={{
                p: 3,
                maxHeight: "400px",
                overflowY: "auto",
                width: "100%",
              }}
            >
              {dailyNews &&
                dailyNews.map((item, i) => (
                  <Box sx={{ p: 3 }} key={i}>
                    <div className="content-algn">
                      <Typography variant="p" className="news-title1">
                        {item?.date}
                      </Typography>
                      <Typography variant="p">
                        <div className="mb-3">
                          <div>
                            <Typography variant="p" className="news-title">
                              {item?.title}
                            </Typography>
                            <div>
                              <img
                                src={item?.image}
                                alt={item?.title}
                                className="news-title-img"
                              />
                            </div>
                            <div
                              className="news-desc"
                              dangerouslySetInnerHTML={{
                                __html: item?.description,
                              }}
                            />

                            <Link
                              className="btn text-white py-2 px-4"
                              to={`/knowmorenews/${item.slug}`}
                            >
                              Know more
                            </Link>
                          </div>
                        </div>
                      </Typography>
                    </div>
                  </Box>
                ))}
            </Box>
          </div>
          <div className="bottom-more">
            <Link className="btn text-white py-2 px-4" to="/news">
              View all
            </Link>
          </div>
        </div>
      </React.Fragment>
    </>
  );
}

export default News;
