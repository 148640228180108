import React, { useEffect, useState } from "react";
import "./newsupdate.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Newsupdate(props) {
  const { monthlyNewsletter, annualCalendar, latestNews } = props;
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentNewsIndex((prevIndex) =>
        prevIndex === latestNews?.length - 1 ? 0 : prevIndex + 1
      );
    }, 20500);

    return () => clearTimeout(timer);
  }, [currentNewsIndex, latestNews]);

  return (
    <>
      <div className="container-fluid py-2">
        <div className="container">
          <div className="row">
            <Container>
              <Row>
                <Col className="newscroll">
                  <h4 className="newsupdate-top">Latest Update</h4>
                </Col>
                <Col xs={6} className="latestnews marquee">
                  {latestNews?.map((news, index) =>
                    index === currentNewsIndex ? (
                      <marquee key={news._id}>
                        <div className="newsupdate-p newsupdate-top scrolling-font">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: news.description,
                            }}
                          />
                        </div>
                      </marquee>
                    ) : null
                  )}
                </Col>
                <Col xs={6} className="latestnews withoutmarqueenews">
                  {latestNews?.map((news, index) =>
                    index === currentNewsIndex ? (
                      <div className="newsupdate-p newsupdate-top">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: news.description,
                          }}
                        />
                      </div>
                    ) : null
                  )}
                </Col>

                {monthlyNewsletter ? (
                  <Col>
                    <a
                      className="btn btn-text2"
                      href={monthlyNewsletter}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Monthly Newsletter
                    </a>
                  </Col>
                ) : null}
                {annualCalendar ? (
                  <Col>
                    <a
                      className="btn btn-text2"
                      href={annualCalendar}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Annual Calendar
                    </a>
                  </Col>
                ) : null}
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}

export default Newsupdate;
