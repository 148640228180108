import React, { useEffect, useState } from "react";
import "../../pages/religiousServices/religiousservices.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import PoojaBasket from "../../components/common/Basket/PoojaBasket";
import { apiEndPoints } from "../../ApiServices/config";
import { postApi } from "../../ApiServices/api";
import { useSelector, useDispatch } from "react-redux";
import { updateCart } from "../../actions";
import { toast } from "react-hot-toast";
function SinglepageView() {
  const [serviceData, setServiceData] = useState();
  const dispatch = useDispatch();
  const [quantityCounts, setQuantityCounts] = useState({});
  const [selectedDate, setSelectedDate] = useState({});
  const cartArr = useSelector((state) => state.cartArr);
  const params = useParams();
  const payload = {
    id: params.slug,
    type: params.category,
  };
  const getSpecificService = async () => {
    const response = await postApi(apiEndPoints.serviceDetails, payload);
    if (response && response.status === 200) {
      setServiceData(response?.data?.data);
    }
  };
  useEffect(() => {
    getSpecificService();
  }, []);
  // Function to handle increasing quantity count for a specific item
  const increaseQuantity = (itemId) => {
    setQuantityCounts((prevCounts) => ({
      ...prevCounts,
      [itemId]: (prevCounts[itemId] || 0) + 1,
    }));
  };
  // Function to handle decreasing quantity count for a specific item
  const decreaseQuantity = (itemId) => {
    if (quantityCounts[itemId] > 1) {
      setQuantityCounts((prevCounts) => ({
        ...prevCounts,
        [itemId]: prevCounts[itemId] - 1,
      }));
    }
  };
  // order puja or add to basket
  const handleOrderPuja = (item) => {
    if (!selectedDate[item._id]) {
      toast.error("Please select a date.");
      return;
    }
    const existingCartItem = cartArr.find(
      (cartItem) => cartItem._id === item._id
    );
    if (existingCartItem) {
      const updatedItem = {
        ...existingCartItem,
        type: "Daily Service",
        type_id: 1,
        location: "",
        page: "services/daily",
        quantity: quantityCounts[item._id] || 1,
        selectedDate: selectedDate[item._id],
      };
      dispatch(updateCart(updatedItem));
    } else {
      const newItem = {
        ...item,
        type: "Daily Service",
        type_id: 1,
        location: "",
        page: "services/daily",
        quantity: quantityCounts[item._id] || 1,
        selectedDate: selectedDate[item._id],
      };
      dispatch(updateCart(newItem));
    }
  };

  function convertUTCtoLocal(utcDateString) {
    const utcDate = new Date(utcDateString);
    const offsetMinutes = utcDate.getTimezoneOffset();
    const localTime = new Date(utcDate.getTime() - offsetMinutes * 60000);
    return localTime;
  }

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          {serviceData?.title}
        </h1>
        <ul className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/services/${serviceData?.category_slug}`}>
              {serviceData?.category}
            </Link>
          </li>
          <li className="breadcrumb-item active">{serviceData?.title}</li>
        </ul>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            {serviceData?.category}
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">{serviceData?.title}</span>
          </h1>
        </div>
        {/* //------------------------------------------------ */}
        <div className="row">
          <div className="col-lg-8 col-md-8">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <img
                  height={300}
                  width={300}
                  src={serviceData?.image}
                  alt={serviceData?.title}
                />
              </div>
              <div className="col-lg-6 col-md-6">
                {/* Date Picker and Quantity */}
                <div className="row mb-2">
                  <div className="col-lg-9 col-md-9">
                    {/* //datepicker based on the services------------------------------ */}
                    {serviceData?.category_slug === "daily" ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          sx={{ width: "100%" }}
                          label="Select a date"
                          value={selectedDate[serviceData?._id]}
                          onChange={(date) =>
                            setSelectedDate((prevDates) => ({
                              ...prevDates,
                              [serviceData?._id]: date,
                            }))
                          }
                          shouldDisableDate={(date) => {
                            const currentDate = new Date();
                            currentDate.setHours(0, 0, 0, 0);
                            new Date(date).setHours(0, 0, 0, 0);
                            return date < currentDate;
                          }}
                        />
                      </LocalizationProvider>
                    ) : serviceData?.category_slug === "weekly" ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Select a date"
                          value={selectedDate[serviceData?._id]}
                          onChange={(date) => {
                            setSelectedDate((prevDates) => ({
                              ...prevDates,
                              [serviceData?._id]: date,
                            }));
                          }}
                          shouldDisableDate={(date) => {
                            const currentDate = new Date();
                            if (date < currentDate) {
                              return true;
                            }
                            return (
                              new Date(date).getDay() !==
                              Number(serviceData?.days)
                            );
                          }}
                        />
                      </LocalizationProvider>
                    ) : serviceData?.category_slug === "monthly" ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Select a date"
                          value={selectedDate[serviceData?._id]}
                          onChange={(date) => {
                            setSelectedDate((prevDates) => ({
                              ...prevDates,
                              [serviceData?._id]: date,
                            }));
                          }}
                          shouldDisableDate={(date) => {
                            const currentDate = new Date();
                            if (date < currentDate) {
                              return true;
                            }

                            const year = new Date(date).getFullYear();
                            const month = new Date(date).getMonth();

                            const firstDayOfMonth = new Date(year, month, 1);

                            const firstDayOfWeek = firstDayOfMonth.getDay();

                            const offset =
                              (serviceData?.days - firstDayOfWeek + 7) % 7;

                            const firstSelectableDate = new Date(
                              year,
                              month,
                              1 +
                                offset +
                                (Number(serviceData?.positions) - 1) * 7
                            );

                            return (
                              new Date(date).getDate() !==
                                firstSelectableDate.getDate() ||
                              new Date(date).getMonth() !==
                                firstSelectableDate.getMonth() ||
                              new Date(date).getFullYear() !==
                                firstSelectableDate.getFullYear()
                            );
                          }}
                        />
                      </LocalizationProvider>
                    ) : serviceData?.category_slug === "annual" ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Select a date"
                          value={selectedDate[serviceData?._id]}
                          onChange={(date) => {
                            setSelectedDate((prevDates) => ({
                              ...prevDates,
                              [serviceData?._id]: date,
                            }));
                          }}
                          shouldDisableDate={(date) => {
                            const currentDate = new Date();
                            const selectedDate = new Date(
                              Number(serviceData?.start)
                            );

                            return (
                              new Date(date) < currentDate ||
                              new Date(date)?.getFullYear() !==
                                selectedDate.getFullYear() ||
                              new Date(date)?.getMonth() !==
                                selectedDate.getMonth() ||
                              new Date(date)?.getDate() !==
                                selectedDate.getDate()
                            );
                          }}
                        />
                      </LocalizationProvider>
                    ) : serviceData?.category_slug === "special" ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Select a date"
                          value={selectedDate[serviceData._id]}
                          onChange={(date) => {
                            setSelectedDate((prevDates) => ({
                              ...prevDates,
                              [serviceData._id]: date,
                            }));
                          }}
                          shouldDisableDate={(date) => {
                            const startDate = new Date(
                              Number(serviceData.start)
                            );
                            const endDate = new Date(Number(serviceData.end));
                            return (
                              date <
                                convertUTCtoLocal(
                                  new Date().setDate(new Date().getDate() - 1)
                                ) || date > endDate
                            );
                          }}
                        />
                      </LocalizationProvider>
                    ) : serviceData?.category_slug === "vahana_sevas" ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Select a date"
                          value={selectedDate[serviceData?._id]}
                          onChange={(date) => {
                            setSelectedDate((prevDates) => ({
                              ...prevDates,
                              [serviceData?._id]: date,
                            }));
                          }}
                          shouldDisableDate={(date) => {
                            const currentDate = new Date();
                            const selectedDate = new Date(
                              Number(serviceData?.start)
                            );

                            return (
                              new Date(date) < currentDate ||
                              new Date(date)?.getFullYear() !==
                                selectedDate.getFullYear() ||
                              new Date(date)?.getMonth() !==
                                selectedDate.getMonth() ||
                              new Date(date)?.getDate() !==
                                selectedDate.getDate()
                            );
                          }}
                        />
                      </LocalizationProvider>
                    ) : (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          sx={{ width: "100%" }}
                          label="Select a date"
                          value={selectedDate[serviceData?._id]}
                          onChange={(date) =>
                            setSelectedDate((prevDates) => ({
                              ...prevDates,
                              [serviceData?._id]: date,
                            }))
                          }
                          shouldDisableDate={(date) => {
                            const currentDate = new Date();
                            currentDate.setHours(0, 0, 0, 0);
                            new Date(date).setHours(0, 0, 0, 0);
                            return date < currentDate;
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="input-group quantity mx-auto">
                      <div className="input-group-btn">
                        <button
                          className="btn btn-sm btn-primary btn-minus"
                          onClick={() => decreaseQuantity(serviceData?._id)}
                        >
                          <i className="fa fa-minus"></i>
                        </button>
                      </div>
                      <input
                        type="text"
                        className="form-control form-control-sm bg-secondary text-center"
                        value={quantityCounts[serviceData?._id] || 1}
                      />
                      <div className="input-group-btn">
                        <button
                          className="btn btn-sm btn-primary btn-plus"
                          onClick={() => increaseQuantity(serviceData?._id)}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Price and Order Button */}
                <div className="row">
                  <div className="col-lg-3 col-md-3 d-flex align-items-center">
                    <h2 className="religious-subtitle">
                      $ {serviceData?.amount}
                    </h2>
                  </div>
                  <div className="col-lg-9 col-md-9">
                    <div style={{ width: "100%" }}>
                      <Button
                        variant="warning text-white btn-slider slider-btn religious-btn"
                        onClick={() => handleOrderPuja(serviceData)}
                        style={{ width: "100%" }}
                      >
                        Order Puja
                      </Button>
                    </div>
                  </div>
                </div>
                <hr />
                {/* Description */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: serviceData?.description,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <PoojaBasket />
          </div>
        </div>
      </div>
    </>
  );
}
export default SinglepageView;
