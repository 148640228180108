import React, { useState, useEffect, useRef } from "react";
import "./header.css";
import LoginIcon from "@mui/icons-material/Login";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function ShowLogin() {
  const navigate = useNavigate();
  const hasToken = localStorage.getItem("token");
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();
  const currentUrlPath = location.pathname;
  const dropdownRef = useRef(null);
  const devoteeData = localStorage.getItem("devoteeData")
    ? JSON.parse(localStorage.getItem("devoteeData"))
    : "";

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogout = () => {
    localStorage.removeItem("devoteeData");
    localStorage.removeItem("token");

    navigate(currentUrlPath);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <>
      {hasToken ? (
        <div className="d-flex align-items-center justify-content-end">
          <div className="dropdown" ref={dropdownRef}>
            <button
              className="btn btn-secondary dropdown-toggle text-light"
              type="button"
              onClick={toggleDropdown}
            >
              <AccountCircleIcon className="top-icon" />{" "}
              {devoteeData?.first_name}
            </button>
            {showDropdown && (
              <ul
                className="dropdown-menu show"
                aria-labelledby="dropdownMenuButton"
                style={{ zIndex: 2000 }}
              >
                <li>
                  <Link className="dropdown-item" to="/">
                    Dashboard
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/profile">
                    Profile
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/changepassword">
                    Change Password
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/profile">
                    Donation Report
                  </Link>
                </li>

                <li>
                  <button className="dropdown-item" onClick={handleLogout}>
                    Logout
                  </button>
                </li>
              </ul>
            )}
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-end">
          <Link
            to={`/login?redirect=${currentUrlPath}`}
            className="text-light me-0"
          >
            <LoginIcon className="top-icon" /> Login
          </Link>
        </div>
      )}
    </>
  );
}

export default ShowLogin;
