import React, { useState, useEffect, useRef } from "react";
import "./header.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Topheader from "./Topheader";
import SearchIcon from "@mui/icons-material/Search";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { apiEndPoints } from "../../../ApiServices/config";
import { getApi } from "../../../ApiServices/api";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";

function Header() {
  const location = useLocation();
  const [donationData, setDonationData] = useState();
  const cartArr = useSelector((state) => state.cartArr);
  const rentCartArr = useSelector((state) => state.rentArr);
  const totalCartCount = cartArr?.length || 0 + rentCartArr?.length || 0;

  const currentUrlPath = location.pathname;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const hasToken = localStorage.getItem("token");
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef(null);
  const devoteeData = JSON.parse(localStorage.getItem("devoteeData"));

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogout = () => {
    localStorage.removeItem("devoteeData");
    localStorage.removeItem("token");

    navigate("/");
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (showDropdown) {
      const handleLinkClick = () => {
        setShowDropdown(false);
      };

      document.querySelectorAll(".dropdown-item").forEach((item) => {
        item.addEventListener("click", handleLinkClick);
      });

      return () => {
        document.querySelectorAll(".dropdown-item").forEach((item) => {
          item.removeEventListener("click", handleLinkClick);
        });
      };
    }
  }, [showDropdown]);

  const getDonations = async () => {
    const response = await getApi(apiEndPoints.getAllDonations);
    if (response && response.status === 200) {
      setDonationData(response?.data?.data);
    }
  };

  useEffect(() => {
    getDonations();
  }, []);

  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Topheader />
      <Navbar
        expanded={expanded}
        collapseOnSelect
        sticky="top"
        expand="lg"
        className="bg-body-tertiary"
        style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <div className="logo">
              <img src="./images/logo.png" alt="logo" />
            </div>
          </Navbar.Brand>
          {windowWidth <= 1050 && (
            <Nav.Link as={Link} to="#">
              <div>
                <img
                  src="./images/logo2.png"
                  alt="logo"
                  style={{ width: "54px" }}
                />
              </div>
            </Nav.Link>
          )}
          <Navbar.Toggle
            onClick={() => setExpanded(!expanded)}
            aria-controls="responsive-navbar-nav"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto justify-content-end w-100">
              <Nav.Link
                as={Link}
                to="/"
                className="menu-link"
                onClick={() => setExpanded(false)}
              >
                Home
              </Nav.Link>
              <NavDropdown
                title="Services"
                id="basic-nav-dropdown"
                className="menu-dropdown"
              >
                <NavDropdown.Item
                  as={Link}
                  to="/expressbookings"
                  className="menu-dropdown-link"
                  onClick={() => setExpanded(false)}
                >
                  Express Booking
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/servicesattemple"
                  className="menu-dropdown-link"
                  onClick={() => setExpanded(false)}
                >
                  Services at the Temple
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/outsidetemple"
                  className="menu-dropdown-link"
                  onClick={() => setExpanded(false)}
                >
                  Services Outside the Temple
                </NavDropdown.Item>

                <NavDropdown
                  title="Donation"
                  onDoubleClick={() => {
                    navigate("/donation");
                  }}
                  id="basic-nav-dropdown"
                  className="menu-dropdown"
                  drop={"end"}
                >
                  {donationData?.map((item, index) => (
                    <NavDropdown.Item
                      key={index}
                      as={Link}
                      to={`/donation/${item.slug}`}
                      className="menu-dropdown-link"
                      onClick={() => setExpanded(false)}
                    >
                      {item.title}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
                <NavDropdown.Item
                  as={Link}
                  to="/priests"
                  className="menu-dropdown-link"
                  onClick={() => setExpanded(false)}
                >
                  Our Priests
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/rentals"
                  className="menu-dropdown-link"
                  onClick={() => setExpanded(false)}
                >
                  Rentals
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title="Events & Activities"
                className="menu-dropdown"
              >
                <NavDropdown.Item
                  as={Link}
                  to="/events"
                  className="menu-dropdown-link"
                  onClick={() => setExpanded(false)}
                >
                  All Events
                </NavDropdown.Item>

                <NavDropdown
                  title="Community"
                  id="basic-nav-dropdown"
                  className="menu-dropdown"
                  drop={"end"}
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/helpingothers"
                    className="menu-dropdown-link"
                    onClick={() => setExpanded(false)}
                  >
                    Helping Hands
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/meraapne"
                    onClick={() => setExpanded(false)}
                    className="menu-dropdown-link"
                  >
                    Mera-Apne
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  title="Cultural"
                  id="basic-nav-dropdown"
                  className="menu-dropdown"
                  drop={"end"}
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/activities"
                    onClick={() => setExpanded(false)}
                    className="menu-dropdown-link"
                  >
                    Cultural Activities
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/culturalevents"
                    className="menu-dropdown-link"
                    onClick={() => setExpanded(false)}
                  >
                    Cultural Events
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  title="Education"
                  id="basic-nav-dropdown"
                  className="menu-dropdown"
                  drop={"end"}
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/balgokul"
                    className="menu-dropdown-link"
                    onClick={() => setExpanded(false)}
                  >
                    BalGokul
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/balgokul-payment"
                    className="menu-dropdown-link"
                    onClick={() => setExpanded(false)}
                  >
                    BalGokul Payment
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="#"
                    className="menu-dropdown-link"
                    onClick={() => setExpanded(false)}
                  >
                    Education Events
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="#"
                    className="menu-dropdown-link"
                    onClick={() => setExpanded(false)}
                  >
                    Education Activities
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown.Item
                  as={Link}
                  to="/events"
                  className="menu-dropdown-link"
                  onClick={() => setExpanded(false)}
                >
                  Other Events
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/ReligiousServices"
                  className="menu-dropdown-link"
                  onClick={() => setExpanded(false)}
                >
                  Religious Events
                </NavDropdown.Item>
                <NavDropdown
                  title="Youth Events"
                  id="basic-nav-dropdown"
                  className="menu-dropdown"
                  drop={"end"}
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/youthtruth"
                    className="menu-dropdown-link"
                    onClick={() => setExpanded(false)}
                  >
                    Youth & Truth
                  </NavDropdown.Item>
                </NavDropdown>
              </NavDropdown>
              <NavDropdown
                title="Gallery"
                id="basic-nav-dropdown"
                className="menu-dropdown"
              >
                <NavDropdown.Item
                  as={Link}
                  to="/gallery/photos"
                  className="menu-dropdown-link"
                  onClick={() => setExpanded(false)}
                >
                  Photos
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/gallery/videos"
                  className="menu-dropdown-link"
                  onClick={() => setExpanded(false)}
                >
                  Videos
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/tvgallery"
                  className="menu-dropdown-link"
                  onClick={() => setExpanded(false)}
                >
                  TV Gallery
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/prayerbooking"
                  className="menu-dropdown-link"
                  onClick={() => setExpanded(false)}
                >
                  Religious Texts
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title="Visit Info"
                id="basic-nav-dropdown"
                className="menu-dropdown"
              >
                <NavDropdown.Item
                  as={Link}
                  to="/location"
                  className="menu-dropdown-link"
                  onClick={() => setExpanded(false)}
                >
                  Location / Directions
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/hotels"
                  className="menu-dropdown-link"
                  onClick={() => setExpanded(false)}
                >
                  Hotels
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/faq"
                  className="menu-dropdown-link"
                  onClick={() => setExpanded(false)}
                >
                  Help / FAQ
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/guidelines"
                  className="menu-dropdown-link"
                  onClick={() => setExpanded(false)}
                >
                  Devotee Guidelines
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="About Us"
                id="basic-nav-dropdown"
                className="menu-dropdown"
              >
                <NavDropdown.Item
                  as={Link}
                  to="/templehistory"
                  className="menu-dropdown-link"
                  onClick={() => setExpanded(false)}
                >
                  Temple's History
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/templetrustees"
                  className="menu-dropdown-link"
                  onClick={() => setExpanded(false)}
                >
                  Board of Trustees
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/executiveboard"
                  className="menu-dropdown-link"
                  onClick={() => setExpanded(false)}
                >
                  Executive Board
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/coordinators"
                  className="menu-dropdown-link"
                  onClick={() => setExpanded(false)}
                >
                  Commitee Coordinators
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="menu-dropdown-link"
                  onClick={() => {
                    if (localStorage.getItem("token")) {
                      navigate("/volunteer");
                    } else {
                      toast.error("Please login to continue");
                    }
                  }}
                >
                  Volunteer Registration
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/contactus"
                  className="menu-dropdown-link"
                  onClick={() => setExpanded(false)}
                >
                  Contact Us
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                as={Link}
                to="/donation"
                className="menu-link"
                onClick={() => setExpanded(false)}
              >
                Donate Now
              </Nav.Link>

              {windowWidth <= 993 && (
                <>
                  {hasToken ? null : (
                    <div className="d-flex align-items-center ">
                      <Link
                        to={`/login?redirect=${currentUrlPath}`}
                        className="menu-link"
                        onClick={() => setExpanded(false)}
                      >
                        Login
                      </Link>
                    </div>
                  )}
                </>
              )}
              <div className="d-flex cartIcon ">
                <Nav.Link as={Link} to="/donation" className="menu-link">
                  <img src="./images/cart.png" alt="cart" />
                  {totalCartCount !== 0 ? (
                    <span className="cart-item-count">{totalCartCount}</span>
                  ) : null}
                </Nav.Link>

                <Nav.Link as={Link} to="/" className="menu-link">
                  <SearchIcon />
                </Nav.Link>
                {windowWidth <= 993 && (
                  <>
                    {hasToken ? (
                      <NavDropdown
                        title={
                          <>
                            <AccountCircleIcon />
                          </>
                        }
                        id="basic-nav-dropdown"
                        className="menu-link relative"
                        show={showDropdown}
                        onToggle={toggleDropdown}
                      >
                        <NavDropdown.Item
                          as={Link}
                          to="/"
                          className="dropdown-item"
                        >
                          {}
                        </NavDropdown.Item>
                        <div
                          as={Link}
                          className="d-flex justify-content-center mb-2"
                        >
                          <AccountCircleIcon className="top-icon" />
                          {devoteeData?.first_name}
                        </div>
                        <NavDropdown.Item
                          as={Link}
                          to="/"
                          className="dropdown-item"
                        >
                          Dashboard
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/profile"
                          className="dropdown-item"
                        >
                          Profile
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/changepassword"
                          className="dropdown-item"
                        >
                          Change Password
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/profile"
                          className="dropdown-item"
                        >
                          Donation Report
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          onClick={handleLogout}
                          className="dropdown-item"
                        >
                          Logout
                        </NavDropdown.Item>
                      </NavDropdown>
                    ) : null}
                  </>
                )}
              </div>
            </Nav>

            {windowWidth >= 1050 && (
              <Nav.Link as={Link} to="#">
                <div className="logo2">
                  <img src="./images/logo2.png" alt="logo" />
                </div>
              </Nav.Link>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
