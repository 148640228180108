import React, { useEffect, useState } from "react";
import "./religiousservices.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button } from "react-bootstrap";
import { postApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";
import { useSelector, useDispatch } from "react-redux";
import { updateCart } from "../../actions";
import { toast } from "react-hot-toast";
import moment from "moment";
import PoojaBasket from "../../components/common/Basket/PoojaBasket";
import { Link } from "react-router-dom";

function ReligiousServices() {
  const cartArr = useSelector((state) => state.cartArr);
  const [religiousServices, setReligiousServices] = useState();
  const dispatch = useDispatch();
  const [quantityCounts, setQuantityCounts] = useState({});
  const [selectedDate, setSelectedDate] = useState({});

  // Function to handle increasing quantity count for a specific item
  const increaseQuantity = (itemId) => {
    setQuantityCounts((prevCounts) => ({
      ...prevCounts,
      [itemId]: (prevCounts[itemId] || 0) + 1,
    }));
  };

  // Function to handle decreasing quantity count for a specific item
  const decreaseQuantity = (itemId) => {
    if (quantityCounts[itemId] > 1) {
      setQuantityCounts((prevCounts) => ({
        ...prevCounts,
        [itemId]: prevCounts[itemId] - 1,
      }));
    }
  };

  const payload = {
    slug: "daily",
  };

  const getReligiousServices = async () => {
    const response = await postApi(apiEndPoints.religiousServices, payload);
    if (response && response.status === 200) {
      setReligiousServices(response?.data?.data);
    }
  };

  useEffect(() => {
    getReligiousServices();
  }, []);

  // order puja or add to basket
  const handleOrderPuja = (item) => {
    if (item.amount !== 0) {
      if (!selectedDate[item._id]) {
        toast.error("Please select a date.");
        return;
      }

      const existingCartItem = cartArr.find(
        (cartItem) => cartItem._id === item._id
      );

      if (existingCartItem) {
        const updatedItem = {
          ...existingCartItem,
          type: "Daily Service",
          type_id: 1,
          location: "",
          page: "services/daily",
          quantity: quantityCounts[item._id] || 1,
          selectedDate: selectedDate[item._id],
        };
        dispatch(updateCart(updatedItem));
        toast.success("Your puja/donation is updated");
      } else {
        const newItem = {
          ...item,
          type: "Daily Service",
          type_id: 1,
          location: "",
          page: "services/daily",
          quantity: quantityCounts[item._id] || 1,
          selectedDate: selectedDate[item._id],
        };
        dispatch(updateCart(newItem));
        toast.success("Your puja/donation added successfully on puja basket");
      }
    } else {
      toast.error(
        "You cannot book this pooja, for more information contact to temple"
      );
    }
  };

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          Religious Services
        </h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/servicesattemple">Services</Link>
          </li>
          <li className="breadcrumb-item active">Religious Services</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Durga Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Religious Services</span>
          </h1>
        </div>
        <p>
          <strong>Please Note:</strong> The schedule may be changed due to
          special events held in temple on that day.
        </p>
        <div className="row">
          <div className="col-lg-9 col-md-8">
            <div className="row">
              {religiousServices?.map((i) => (
                <div key={i._id} className="col-lg-4 col-md-6">
                  <div className="religious-services-box">
                    <div className="religious-service-item d-block rounded text-center h-100 p-3">
                      <Link to={`/services/${i.category_slug}/${i.slug}`}>
                        <img
                          className="img-fluid rounded mb-2"
                          src={i.image}
                          alt=""
                        />
                      </Link>
                      <h4 className="mb-0 religious-services-title">
                        {i.title}
                      </h4>
                      <div className="d-flex justify-content-between mb-2">
                        <div className="religious-subtitle">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="Select a date"
                              value={selectedDate[i._id]}
                              onChange={(date) =>
                                setSelectedDate((prevDates) => ({
                                  ...prevDates,
                                  [i._id]: date,
                                }))
                              }
                              shouldDisableDate={(date) => {
                                const currentDate = moment
                                  .tz("America/New_York")
                                  .startOf("day");
                                return date.isBefore(currentDate, "day");
                              }}
                            />
                          </LocalizationProvider>
                        </div>

                        <div className="religious-subtitle">
                          <div className="input-group quantity mx-auto">
                            <div className="input-group-btn">
                              <button
                                className="btn btn-sm btn-primary btn-minus"
                                onClick={() => decreaseQuantity(i._id)}
                              >
                                <i className="fa fa-minus"></i>
                              </button>
                            </div>
                            <input
                              type="text"
                              className="form-control form-control-sm bg-secondary text-center"
                              value={quantityCounts[i._id] || 1}
                            />
                            <div className="input-group-btn">
                              <button
                                className="btn btn-sm btn-primary btn-plus"
                                onClick={() => increaseQuantity(i._id)}
                              >
                                <i className="fa fa-plus"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <h2 className="religious-subtitle">
                          {/* <span>4th Saturday</span> */}
                          <Button
                            variant="warning text-white btn-slider slider-btn religious-btn"
                            onClick={() => handleOrderPuja(i)}
                          >
                            Order Puja
                          </Button>
                        </h2>
                        <h2 className="religious-subtitle">
                          <span>
                            {/* <QueryBuilderIcon className="time-icon" />
                            09:00 */}
                          </span>
                          $ {i.amount !== 0 ? i.amount : "-"}
                        </h2>
                      </div>
                      <div
                        className="service-content-bdr truncate"
                        dangerouslySetInnerHTML={{
                          __html: i?.description,
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* basket ------------------------------------------- */}
          <div className="col-lg-3 col-md-4">
            <PoojaBasket />
          </div>
        </div>
      </div>
    </>
  );
}

export default ReligiousServices;
