import React from "react";
import { Link } from "react-router-dom";

function Copyright() {
  return (
    <div className="container-fluid copyright py-4">
      <div className="container">
        <div className="row g-4 align-items-center">
          <div className="col-md-6 text-center text-md-start mb-md-0">
            <span className="text-white">
              Copyright © 2024 <Link to="/">Durga Temple of Virginia</Link>, All
              right reserved.
            </span>
          </div>
          <div className="col-md-6 text-center text-md-end text-white">
            Designed & Developed by{" "}
            <a className="border-bottom" href="https://digiteratech.com/">
              Digitera Technologies
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Copyright;
