import axios from "axios";

const { REACT_APP_API_URL } = process.env;

const axiosInstanse = axios.create({
  baseURL: REACT_APP_API_URL,
  headers: {
    Authorization: localStorage.getItem("token"),
  },
});

export const getApi = async (path) => {
  try {
    let response = await axiosInstanse.get(path);
    if (response?.data?.token && response?.data?.token !== null) {
      localStorage.setItem("token", response?.data?.token);
    }

    return response;
  } catch (error) {
    if (error && error.response) {
      if (error && error.response && error.response.status === 400) {
      }
    }
  }
};

export const postApi = async (path, data) => {
  try {
    const response = await axiosInstanse.post(path, data);

    if (response.data.token && response.data.token !== null) {
      localStorage.setItem("token", response?.data?.token);
    }

    return response;
  } catch (error) {
    if (error && error.response) {
      if (error && error.response.data && error.response.status === 401) {
      }
    }
  }
};

export const EditApi = async (path, data) => {
  try {
    const response = await axiosInstanse.put(path, data, {
      headers: {
        Authorization: localStorage?.getItem("token"),
      },
    });

    if (response.data.token && response.data.token !== null) {
      localStorage.setItem("token", response.data.token);
    }

    return response;
  } catch (error) {
    if (error && error.response) {
      if (error && error.response && error.response.status === 400) {
      }
    }
  }
};

export const deleteApi = async (path) => {
  try {
    const response = await axiosInstanse.delete(path, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });

    if (response.data.token && response.data.token !== null) {
      localStorage.setItem("token", response.data.token);
    }

    return response;
  } catch (error) {
    if (error && error.response) {
      if (error && error.response && error.response.status === 400) {
      }
    }
  }
};

export const deleteManyApi = async (path, data) => {
  try {
    const response = await axiosInstanse.post(path, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    if (response.data.token && response.data.token !== null) {
      localStorage.setItem("token", response?.data?.token);
    }

    return response;
  } catch (error) {
    if (error && error.response) {
      if (error && error.response.data && error.response.status === 401) {
      }
    }
  }
};
